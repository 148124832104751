import React from "react";
import Form from "react-bootstrap/esm/Form";
import Icon from "src/components/ui/icons";
import { cn } from "src/lib/utils";

interface FormControlErrorProps {
  error?: string | string[] | null;
  className?: string;
  style?: React.CSSProperties;
  absolute?: boolean;
}

const FormControlError: React.FC<FormControlErrorProps> = (
  {
    error,
    style,
    className,
    absolute = true,
  },
) => {
  const errorString = React.useMemo(() => {
    if (Array.isArray(error)) {
      return error.join(" ");
    }
    return error;
  }, [error]);

  const classNames = React.useMemo(() => {
    let defaultClassName = "form-control-error form-control-error--with-icon";
    if (absolute) {
      defaultClassName += " form-control-error--absolute";
    }
    if (className) {
      return `${className} ${defaultClassName}`;
    }
    return defaultClassName;
  }, [className, absolute]);

  if (!error) {
    return null;
  }

  return (
    // @ts-ignore
    <Form.Control.Feedback
      className={cn("!flex !flex-row !items-center", classNames)}
      type="invalid"
      style={style}
    >
      <Icon name="hint" size={14} variant="danger" className="form-control-error__icon" />
      {errorString}
    </Form.Control.Feedback>
  );
};

export default FormControlError;

import React, { useMemo } from "react";
import { Card } from "src/components/ui/card";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getDashboardData, runPreloadMonitors, runDailyMonitors } from "src/api/admin/local-pulse";
import CenterPreloader from "src/components/loaders/CenterPreloader";
import { Button } from "src/components/ui/button";
import { useToastStore } from "src/store/toast";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Loader2, RefreshCcw } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import DateRestrictForm from "src/pages/admin/local-pulse/component/date-restrict-form";
import { TestResult } from "src/pages/admin/local-pulse/local-monitor/TestMonitor";

// eslint-disable-next-line no-shadow
export enum TestState {
  CONFIRMING = "CONFIRMING",
  LOADING = "LOADING",
  RUNNING = "RUNNING",
  ERROR = "ERROR",
}

type TestMonitorResultType = AdminAPI.LocalPulse.RunPreloadMonitors.Response["data"];

const PreloadDialog = ({
  isOpen,
  setIsOpen,
  runMutation,
  testMonitorResult,
  testState,
  setTestState,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  runMutation: any;
  testMonitorResult: TestMonitorResultType;
  testState: TestState;
  setTestState: (state: TestState) => void;
}) => {
  const [newsCount, setNewsCount] = React.useState<number>(100);
  const [dateRestrict, setDateRestrict] = React.useState("y1");
  const [loadDateRestrict, setLoadDateRestrict] = React.useState(true);

  const testMonitorContent = useMemo(() => {
    switch (testState) {
      case TestState.CONFIRMING:
        return (
          <>
            <div className="grid gap-4 py-4 px-3">
              <div className="space-y-2">
                <Label htmlFor="newsCount">Number of news items</Label>
                <Input
                  id="newsCount"
                  value={newsCount}
                  type="number"
                  onChange={(e) => {
                    setNewsCount(e.target.value ? parseInt(e.target.value, 10) : 0);
                  }}
                  placeholder="Enter number of news items"
                />
              </div>
              <div className="space-y-2">
                <DateRestrictForm
                  initialDateRestrict={dateRestrict}
                  onDateRestrictChange={(changedDateRestrict) => {
                    setDateRestrict(changedDateRestrict);
                  }}
                  load={loadDateRestrict}
                  setLoad={setLoadDateRestrict}
                />
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  runMutation.mutate({
                    num_of_results: newsCount,
                    date_restrict: dateRestrict,
                  });
                  setTestState(TestState.LOADING);
                }}
                disabled={runMutation.isLoading || !newsCount || !dateRestrict}
              >
                {runMutation.isLoading ? "Running..." : "Run Preload"}
              </Button>
            </DialogFooter>
          </>
        );

      case TestState.LOADING:
        return (
          <div className="flex flex-col items-center justify-center py-8 space-y-4 px-3">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
            <p className="text-muted-foreground">Initializing test...</p>
          </div>
        );

      case TestState.RUNNING:
        return (
          <>
            <div className="space-y-4 p-3">
              <div className="flex items-center space-x-2">
                <div className="h-2 w-2 rounded-full bg-green-500 animate-pulse" />
                <p className="text-sm font-medium mb-0">Test in progress</p>
              </div>

              <div className="max-h-[400px] overflow-y-auto rounded-md border customScrollbar2">
                {testMonitorResult.length > 0 ? (
                  <div className="divide-y">
                    {testMonitorResult.map((result, index) => (
                      <div key={result.link + result.title} className="p-4 hover:bg-muted/50 transition-colors">
                        <div className="text-xs text-muted-foreground mb-2">Result {index + 1}</div>
                        <TestResult result={result} index={index} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="p-4 text-center text-muted-foreground">No results found</div>
                )}
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setTestState(TestState.CONFIRMING);
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </>
        );

      case TestState.ERROR:
        return (
          <>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <div className="h-2 w-2 rounded-full bg-red-500 animate-pulse" />
                <p className="text-sm font-medium mb-0">Error running test</p>
              </div>

              <div className="max-h-[400px] overflow-y-auto rounded-md border customScrollbar2">
                <div className="p-4 text-center text-muted-foreground">Error running test</div>
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setTestState(TestState.CONFIRMING);
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </>
        );

      default:
        return null;
    }
  }, [testState, setIsOpen, testMonitorResult, newsCount, dateRestrict]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Configure Preload News</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        {testMonitorContent}
      </DialogContent>
    </Dialog>
  );
};

const DailyDialog = ({
  isOpen,
  setIsOpen,
  runMutation,
  testMonitorResult,
  testState,
  setTestState,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  runMutation: any;
  testMonitorResult: TestMonitorResultType;
  testState: TestState;
  setTestState: (state: TestState) => void;
}) => {
  const testMonitorContent = useMemo(() => {
    switch (testState) {
      case TestState.CONFIRMING:
        return (
          <>
            <div className="p-3 text-center">
              <p>Are you sure you want to run a test with this area?</p>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  runMutation.mutate({});
                  setTestState(TestState.LOADING);
                }}
                disabled={runMutation.isLoading}
              >
                {runMutation.isLoading ? "Running..." : "Run Daily News"}
              </Button>
            </DialogFooter>
          </>
        );

      case TestState.LOADING:
        return (
          <div className="flex flex-col items-center justify-center py-8 space-y-4 px-3">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
            <p className="text-muted-foreground">Initializing test...</p>
          </div>
        );

      case TestState.RUNNING:
        return (
          <>
            <div className="space-y-4 p-3">
              <div className="flex items-center space-x-2">
                <div className="h-2 w-2 rounded-full bg-green-500 animate-pulse" />
                <p className="text-sm font-medium mb-0">Test in progress</p>
              </div>

              <div className="max-h-[400px] overflow-y-auto rounded-md border customScrollbar2">
                {testMonitorResult.length > 0 ? (
                  <div className="divide-y">
                    {testMonitorResult.map((result, index) => (
                      <div key={uuidv4()} className="p-4 hover:bg-muted/50 transition-colors">
                        <div className="text-xs text-muted-foreground mb-2">Result {index + 1}</div>
                        <TestResult result={result} index={index} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="p-4 text-center text-muted-foreground">No results found</div>
                )}
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setTestState(TestState.CONFIRMING);
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </>
        );

      case TestState.ERROR:
        return (
          <>
            <div className="space-y-4 p-3">
              <div className="flex items-center space-x-2">
                <div className="h-2 w-2 rounded-full bg-red-500 animate-pulse" />
                <p className="text-sm font-medium mb-0">Error running test</p>
              </div>

              <div className="max-h-[400px] overflow-y-auto rounded-md border customScrollbar2">
                <div className="p-4 text-center text-muted-foreground">Error running test</div>
              </div>
            </div>
            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setTestState(TestState.CONFIRMING);
                  setIsOpen(false);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </>
        );

      default:
        return null;
    }
  }, [testState, setIsOpen, testMonitorResult]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Run Daily News</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        {testMonitorContent}
      </DialogContent>
    </Dialog>
  );
};

const Dashboard: React.FC = () => {
  const { addToastDanger, addToastSuccess } = useToastStore();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: async () => {
      const response = await getDashboardData();
      return response;
    },
    staleTime: 1000 * 60, // Data becomes stale after 1 minute
    refetchOnWindowFocus: false, // Disable automatic refetch on window focus
    refetchOnMount: true,
    // Only refetch if the data is older than 5 minutes
    refetchInterval: 1000 * 60 * 5,
  });
  const [isPreloadDialogOpen, setIsPreloadDialogOpen] = React.useState(false);
  const [testMonitorResult, setTestMonitorResult] = React.useState<TestMonitorResultType>([]);
  const [preloadTestState, setPreloadTestState] = React.useState<TestState>(TestState.CONFIRMING);
  const [hasPreloaded, setHasPreloaded] = React.useState<boolean>(false);

  const [isDailyDialogOpen, setIsDailyDialogOpen] = React.useState(false);
  const [dailyTestState, setDailyTestState] = React.useState<TestState>(TestState.CONFIRMING);
  const [dailyTestMonitorResult, setDailyTestMonitorResult] = React.useState<TestMonitorResultType>([]);

  const runDailyMutation = useMutation({
    mutationFn: runDailyMonitors,
    onSuccess: (response) => {
      addToastSuccess({
        title: "Success",
        body: "All active monitors have been triggered",
      });
      setDailyTestState(TestState.RUNNING);
      setDailyTestMonitorResult(response.data.data);
    },
    onError: (error) => {
      addToastDanger({
        title: "Error",
        body: "Failed to trigger monitors",
      });
      setDailyTestState(TestState.ERROR);
    },
  });

  const runPreloadMutation = useMutation({
    mutationFn: runPreloadMonitors,
    onSuccess: (response) => {
      addToastSuccess({
        title: "Success",
        body: "All active monitors have been triggered",
      });
      setPreloadTestState(TestState.RUNNING);
      setTestMonitorResult(response.data.data);
      setHasPreloaded(true);
    },
    onError: (error) => {
      addToastDanger({
        title: "Error",
        body: "Failed to trigger monitors",
      });
      setPreloadTestState(TestState.ERROR);
    },
  });

  const totalMonitors = useMemo(() => data?.data.total_monitors || 0, [data]);
  const activeMonitors = useMemo(() => data?.data.active_monitors || 0, [data]);
  const pendingInitialRun = useMemo(() => data?.data.pending_initial_run || 0, [data]);
  const totalNews = useMemo(() => data?.data.total_news || 0, [data]);

  React.useEffect(() => {
    if (!isPreloadDialogOpen) {
      setTestMonitorResult([]);
    }
  }, [isPreloadDialogOpen]);

  React.useEffect(() => {
    if (!isDailyDialogOpen) {
      setDailyTestMonitorResult([]);
    }
  }, [isDailyDialogOpen]);

  if (isLoading) {
    return <CenterPreloader />;
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Local Pulse Dashboard</h1>
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              // Force a refetch of the data
              refetch();
            }}
          >
            <RefreshCcw className="w-4 h-4" />
          </Button>
          <Button
            onClick={() => setIsDailyDialogOpen(true)}
            variant="outline"
            disabled={runDailyMutation.isLoading || activeMonitors === 0}
          >
            {runDailyMutation.isLoading ? "Running..." : "Run Daily News"}
          </Button>
          <Button
            onClick={() => setIsPreloadDialogOpen(true)}
            disabled={runPreloadMutation.isLoading || pendingInitialRun === 0 || hasPreloaded}
            variant="outline"
          >
            {runPreloadMutation.isLoading ? "Running..." : "Run Initial News"}
          </Button>
        </div>
      </div>

      <PreloadDialog
        isOpen={isPreloadDialogOpen}
        setIsOpen={setIsPreloadDialogOpen}
        runMutation={runPreloadMutation}
        testMonitorResult={testMonitorResult}
        testState={preloadTestState}
        setTestState={setPreloadTestState}
      />
      <DailyDialog
        isOpen={isDailyDialogOpen}
        setIsOpen={setIsDailyDialogOpen}
        runMutation={runDailyMutation}
        testMonitorResult={dailyTestMonitorResult}
        testState={dailyTestState}
        setTestState={setDailyTestState}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Card className="p-3 rounded-md">
          <h3 className="text-sm font-medium text-muted-foreground">Total Monitors</h3>
          <p className="text-xl font-bold mb-0">{totalMonitors}</p>
        </Card>

        <Card className="p-3 rounded-md">
          <h3 className="text-sm font-medium text-muted-foreground">Active Monitors</h3>
          <p className="text-xl font-bold mb-0">{activeMonitors}</p>
        </Card>

        <Card className="p-3 rounded-md">
          <h3 className="text-sm font-medium text-muted-foreground">Inactive Monitors</h3>
          <p className="text-xl font-bold mb-0">{totalMonitors - activeMonitors}</p>
        </Card>

        <Card className="p-3 rounded-md">
          <h3 className="text-sm font-medium text-muted-foreground">Pending Initial Run</h3>
          <p className="text-xl font-bold mb-0">{pendingInitialRun}</p>
        </Card>
        <Card className="p-3 rounded-md">
          <h3 className="text-sm font-medium text-muted-foreground">Total News</h3>
          <p className="text-xl font-bold mb-0">{totalNews}</p>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;

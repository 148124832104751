import React from "react";
import { useTranslation } from "react-i18next";
import { useLoginRequest } from "src/hooks/api/authentication";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";
import { useAuthStore } from "src/store/account";
import { useNextPathNavigate } from "src/hooks/redirect";
import { RequestEmailVerification, RequestPhoneVerification } from "src/components/forms/verifications";
import { Fingerprint, Eye, EyeOff, Lock } from "lucide-react";
import { Button } from "src/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/zForm";
import { Input } from "src/components/ui/input";
import { Checkbox } from "src/components/ui/checkbox";
import { Alert, AlertDescription } from "src/components/ui/alert";
import { Link } from "react-router-dom";

type FormValue = API.Authentication.ObtainToken.Request;

const initialFormValue: FormValue = {
  username: "",
  password: "",
  is_remember: false,
};

interface TestContext {
  createError: (params: { message: string }) => any;
}

const LoginForm: React.FC = () => {
  const { t } = useTranslation();

  const requiredMessage = i18next.t("field_required_error", "This field is required") || "This field is required";
  const emailInvalidMessage = i18next.t("field_email_error", "Enter a valid email address") || "Enter a valid email address";
  const phoneInvalidMessage =
    i18next.t(
      "field_phone_error",
      "Enter a valid phone number. The phone number should start with +1 and contain only digits without spaces",
    ) || "Enter a valid phone number. The phone number should start with +1 and contain only digits without spaces";

  const emailSchema = yup.string().email(emailInvalidMessage).required(requiredMessage);

  const testEmailOrPhone = (value: any, { createError }: TestContext) => {
    if (!value) {
      return createError({
        message: requiredMessage,
      });
    }

    if (value.startsWith("+") || !Number.isNaN(parseInt(value, 10))) {
      if (value.match(/^\+[0-9]{11}$/g) === null) {
        return createError({
          message: phoneInvalidMessage,
        });
      }
      return true;
    }

    return emailSchema.validateSync(value);
  };

  const schema = yup
    .object({
      username: yup.string().test("email_or_phone", testEmailOrPhone),
      password: yup.string().required(requiredMessage),
      is_remember: yup.boolean(),
    })
    .required();

  const form = useForm<FormValue>({
    defaultValues: initialFormValue,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const {
    setError,
    formState: { errors, isValid },
    handleSubmit,
  } = form;

  const { error, mutate, status, data, isError, isLoading } = useLoginRequest();

  const [previousFormData, setPreviousFormData] = React.useState<Partial<FormValue> | null>(null);

  const onSubmit = (formData: FormValue) => {
    if (!isValid) {
      return;
    }
    setPreviousFormData({
      ...formData,
      password: "",
    });
    mutate(formData);
  };

  const { setAccessToken, setRefreshToken, setIsAuthenticated } = useAuthStore();

  const nextPathNavigate = useNextPathNavigate();

  React.useEffect(() => {
    if (status === "success" && data?.data) {
      setAccessToken(data.data.access);
      setRefreshToken(data.data.refresh);
      setIsAuthenticated(true);
      nextPathNavigate();
    }
  }, [status]);

  React.useEffect(() => {
    if (isError && error.response?.data) {
      const errorData = error.response.data as Record<string, string>;
      Object.keys(initialFormValue).forEach((key) => {
        if (errorData[key]) {
          form.setError(key as keyof FormValue, {
            type: "server",
            message: errorData[key],
          });
        }
      });
    }
  }, [isError, error]);

  const canRequestEmailVerification = React.useMemo(() => {
    if (!error?.response?.data?.code) {
      return false;
    }

    const hasEmail = previousFormData?.username?.includes("@");
    if (typeof error.response.data.code === "string") {
      return hasEmail && error.response.data.code === "email_not_confirmed";
    }

    return hasEmail && error.response.data.code.includes("email_not_confirmed");
  }, [error, previousFormData]);

  const canRequestPhoneVerification = React.useMemo(() => {
    if (!error?.response?.data?.code) {
      return false;
    }

    const hasPhone = previousFormData?.username?.startsWith("+1");
    if (typeof error.response.data.code === "string") {
      return hasPhone && error.response.data.code === "phone_not_confirmed";
    }

    return hasPhone && error.response.data.code.includes("phone_not_confirmed");
  }, [error, previousFormData]);

  return (
    <Form {...form}>
      <form name="login" noValidate onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {error?.response?.data?.detail && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>{error.response.data.detail}</AlertDescription>
          </Alert>
        )}

        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("Email or phone number")}</FormLabel>
              <div className="relative">
                <FormControl>
                  <div className="relative">
                    <Fingerprint className="absolute left-3 top-1/2 -translate-y-1/2 text-primary h-4 w-4" />
                    <Input
                      {...field}
                      type="text"
                      autoComplete="login"
                      placeholder={t("Email or phone number") as string}
                      className="pl-10"
                      tabIndex={1}
                    />
                  </div>
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => {
            const [showPassword, setShowPassword] = React.useState(false);
            return (
              <FormItem>
                <FormLabel>{t("registration_form.password_label", "Password")}</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-primary h-4 w-4" />
                    <Input
                      type={showPassword ? "text" : "password"}
                      className={`pl-10 pr-3 ${showPassword ? "" : "tracking-[0.25em]"} [&::placeholder]:tracking-[0.25em] [&::-ms-reveal]:hidden [&::-ms-clear]:hidden [&::-webkit-credentials-auto-fill-button]:hidden [&::-webkit-contacts-auto-fill-button]:hidden`}
                      autoComplete="current-password"
                      placeholder="••••••••"
                      tabIndex={2}
                      {...field}
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="absolute right-0 top-0 h-full px-3 py-2"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4 text-muted-foreground" />
                      ) : (
                        <Eye className="h-4 w-4 text-muted-foreground" />
                      )}
                      <span className="sr-only">{showPassword ? "Hide password" : "Show password"}</span>
                    </Button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <div className="flex flex-col space-y-2">
          <FormField
            control={form.control}
            name="is_remember"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between space-x-3 space-y-0">
                <div className="space-x-3 flex flex-row items-center">
                  <FormControl>
                    <Checkbox
                      id="is_remember"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      tabIndex={3}
                      className="rounded-[4px]"
                    />
                  </FormControl>
                  <FormLabel
                    htmlFor="is_remember"
                    color="muted"
                    className="text-sm font-normal mt-0 cursor-pointer hover:text-foreground"
                  >
                    {t("Keep me logged in")}
                  </FormLabel>
                </div>
                <div className="flex justify-end">
                  <Link
                    to="/login/forgot-password/"
                    className="text-sm font-normal no-underline text-muted-foreground hover:text-foreground hover:underline"
                  >
                    {t("Forgot password?")}
                  </Link>
                </div>
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" className="w-full" disabled={isLoading || !form.formState.isValid} tabIndex={4}>
          {isLoading ? t("Signing in...") : t("Sign In")}
        </Button>

        {canRequestEmailVerification && <RequestEmailVerification className="pt-4" email={previousFormData?.username!} />}

        {canRequestPhoneVerification && <RequestPhoneVerification className="pt-4" phone={previousFormData?.username!} />}
      </form>
    </Form>
  );
};

export default LoginForm;

import React, { useMemo, useState, useRef } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { useMutation, useQueryClient, InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { CirclePlay, Info, PencilIcon, Trash, Variable } from "lucide-react";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { Textarea } from "src/components/ui/textarea";
import { HoverTooltip } from "src/components/ui/hoverTooltip";
import { createNewMonitor, deleteMonitorConfig, updateMonitorConfig } from "src/api/admin/local-pulse";
import { CenterPreloader } from "src/components/loaders";
import { MultiSelect, SelectOption } from "src/components/ui/multiSelect";
import { useGetMonitorsCategoriesRequest } from "src/hooks/api/local-pulse";
import AirvanaTooltip from "src/components/ui/AirvanaTooltips";
import { Select, SelectItem, SelectContent, SelectTrigger, SelectValue } from "src/components/ui/select";
import { Switch } from "src/components/ui/switch";
import DateRestrictForm from "src/pages/admin/local-pulse/component/date-restrict-form";
import { UrlInputForm } from "src/pages/admin/local-pulse/component/url-input-form";
import TestMonitor from "src/pages/admin/local-pulse/local-monitor/TestMonitor";
import AreaInputForm from "src/pages/admin/local-pulse/component/area-input-form";
import ExistingMonitorForm from "src/pages/admin/local-pulse/component/existing-monitor-form";

interface EditableTableProps {
  search: string;
  handleSearch: (search: string) => void;
  isLoading: boolean;
  data: InfiniteData<AxiosResponse<AdminAPI.LocalPulse.monitorConfigList.Response, any>> | undefined;
}

const QUERY_VARIABLES = [
  { label: "Area Name", value: "{{AreaName}}" },
  { label: "Area Type", value: "{{AreaType}}" },
  { label: "Area State", value: "{{AreaState}}" },
  { label: "Area State Code", value: "{{AreaStateCode}}" },
];

const METHODS = [
  { label: "Standard Search", value: "standard", description: "Search for results in the selected area" },
  { label: "Targeted Search", value: "targeted", description: "Search for results in the selected area and target specific URLs" },
];

const FILE_TYPES = [
  { label: "PDF", value: "pdf", description: "Adobe Portable Document Format (.pdf)" },
  { label: "PostScript", value: "ps", description: "Adobe PostScript (.ps)" },
  { label: "CSV", value: "csv", description: "Comma-Separated Values (.csv)" },
  { label: "RSS", value: "rss", description: "Rich Site Summary (RSS)" },
  { label: "Excel", value: "excel", description: "Microsoft Excel (.xls, .xlsx)" },
  { label: "PowerPoint", value: "ppt", description: "Microsoft PowerPoint (.ppt, .pptx)" },
  { label: "TXT", value: "txt", description: "Plain Text (.txt, .text)" },
  { label: "Word", value: "doc", description: "Microsoft Word (.doc, .docx)" },
  { label: "XML", value: "xml", description: "Extensible Markup Language (XML)" },
];

export default function EditableTable({ search, handleSearch, isLoading, data }: EditableTableProps) {
  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [editData, setEditData] = useState<Partial<AdminAPI.LocalPulse.MonitorConfig>>({});
  const [isEditing, setIsEditing] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [initName, setInitName] = useState<string>("");
  const [testMonitor, setTestMonitor] = useState<AdminAPI.LocalPulse.MonitorConfig | null>(null);
  const [flattenedData, setFlattenedData] = useState<AdminAPI.LocalPulse.MonitorConfig[]>([]);
  const [selectedData, setSelectedData] = useState<AdminAPI.LocalPulse.MonitorConfig | null>(null);
  const [loadDateRestrict, setLoadDateRestrict] = useState(true);
  const [isAutoNaming, setIsAutoNaming] = useState(true);
  const queryClient = useQueryClient();
  const queryRef = useRef<HTMLTextAreaElement>(null);
  const hqRef = useRef<HTMLTextAreaElement>(null);
  const exactTermsRef = useRef<HTMLTextAreaElement>(null);
  const dialogDataDisplay = (selectedID: number) => {
    setSelectedRowId(selectedID);
    if (selectedID === 0) {
      setSelectedData(null);
      setInitName("");
      setInitialLoad(true);
    } else if (selectedID === -1) {
      setSelectedData({
        id: Math.floor(Math.random() * 1000000),
        name: "New Monitor",
        query: "",
        hq: "",
        exclude_terms: "",
        exact_terms: "",
        duplicate_filter: true,
        num_of_results: 10,
        urls: [],
        date_restrict: "y100",
        categories: [],
        categories_name: [],
        area_ids: [],
        area_names: [],
        method: "standard",
        file_type: "",
      });
      setInitName("New Monitor");
    } else {
      const selectedRow = flattenedData.find((row) => row.id === selectedID);
      setSelectedData(selectedRow || null);
      setInitName(selectedRow?.name || "");
      setLoadDateRestrict(true);
    }
  };
  const updateMutation = useMutation({
    mutationFn: (updateData: AdminAPI.LocalPulse.UpdateMonitorConfig.Request) => updateMonitorConfig(updateData),
    onSuccess: () => {
      // Invalidate and refetch the monitors list
      queryClient.invalidateQueries({ queryKey: ["monitors"] });

      // Reset the form state
      setIsEditing(false);
      setEditData({});
      dialogDataDisplay(0);
    },
    onError: (error) => {
      // Handle error - you might want to show a toast notification
      console.error("Failed to update monitor:", error);
    },
  });
  const { data: categoriesData, isLoading: isCategoriesLoading } = useGetMonitorsCategoriesRequest();

  const categories = useMemo(() => {
    if (!categoriesData || !categoriesData.data) return [];
    return categoriesData.data.map((category: { id: number; name: string }) => ({
      value: category.id,
      label: category.name,
    }));
  }, [categoriesData]);

  const handleEditDialog = (open: boolean) => {
    setIsEditing(open);
    if (!open) {
      setEditData({});
      setSelectedRowId(0);
      setInitialLoad(true);
      setSelectedData(null);
    }
  };

  const handleDeleteDialog = (open: boolean) => {
    setIsDeleting(open);
    if (!open) {
      setSelectedRowId(0);
    }
  };

  const handleSave = async () => {
    // Here you would typically update your data source
    const { categories_name: _, area_names: __, ...dataToUpdate } = editData;
    handleEditDialog(false);

    if (selectedRowId === -1) {
      //create a new monitor
      const results = await createNewMonitor({
        name: dataToUpdate.name || "New Monitor",
        query: dataToUpdate.query || "Short-term rental",
        date_restrict: dataToUpdate.date_restrict || "y100",
        urls: dataToUpdate.urls || [],
        duplicate_filter: dataToUpdate.duplicate_filter || true,
        num_of_results: dataToUpdate.num_of_results || 10,
        categories: dataToUpdate.categories || [],
        exclude_terms: dataToUpdate.exclude_terms || "",
        exact_terms: dataToUpdate.exact_terms || "",
        hq: dataToUpdate.hq || "",
        method: dataToUpdate.method || "standard",
        area_ids: dataToUpdate.area_ids || [],
      });
      setFlattenedData([
        {
          id: results.data.id,
          name: results.data.name,
          query: results.data.query || "",
          date_restrict: results.data.date_restrict || "y100",
          urls: results.data.urls || [],
          duplicate_filter: results.data.duplicate_filter || true,
          num_of_results: results.data.num_of_results || 10,
          categories: results.data.categories || [],
          exclude_terms: results.data.exclude_terms || "",
          exact_terms: results.data.exact_terms || "",
          hq: results.data.hq || "",
          categories_name: results.data.categories_name || [],
          area_ids: results.data.area_ids || [],
          area_names: results.data.area_names || [],
          method: results.data.method || "standard",
          file_type: results.data.file_type || "",
        },
        ...flattenedData,
      ]);
      return;
    }

    updateMutation.mutate({
      ...dataToUpdate,
      id: selectedRowId,
    });
    setFlattenedData(flattenedData.map((row) => (row.id === selectedRowId ? { ...row, ...dataToUpdate } : row)));
  };

  const deleteMonitor = async (id: number) => {
    try {
      const results = await deleteMonitorConfig({ ids: [id] });
      setFlattenedData(flattenedData.filter((row) => row.id !== id));
      handleDeleteDialog(false);
    } catch (error) {
      console.error("Failed to delete monitor:", error);
    }
  };

  const formatMonitorScope = (areas: string[] = [], tempCategories: string[] = [], maxItems = 2) => {
    const formatList = (items: string[], separator: string, useFirstChar = false) => {
      if (!items?.length) return "";
      const formattedItems = useFirstChar ? items.map((item) => item.charAt(0).toUpperCase()) : items;

      if (items.length <= maxItems) return formattedItems.join(separator);
      return `${formattedItems.slice(0, maxItems).join(separator)} +${items.length - maxItems}`;
    };

    const areaStr = formatList(areas, " / ");
    const categoryStr = formatList(tempCategories, " | ", true);

    if (!areaStr && !categoryStr) return selectedData?.name || "New Monitor";
    if (!categoryStr) return `${areaStr}`;
    if (!areaStr) return `(${categoryStr})`;
    return `${areaStr} (${categoryStr})`;
  };

  const handleAutoNamingChange = (checked: boolean) => {
    setIsAutoNaming(checked);
    if (!checked) {
      setEditData((prev) => {
        const { name, ...rest } = prev;
        return rest;
      });
      setSelectedData((prev) => (prev ? { ...prev, name: initName } : null));
    }
  };

  const handleVariableInsert = (type: "hq" | "exact_terms" | "query", value: string) => {
    if (!selectedData) return;
    let textarea: HTMLTextAreaElement | null = null;

    if (type === "hq") {
      textarea = hqRef.current;
    } else if (type === "exact_terms") {
      textarea = exactTermsRef.current;
    } else if (type === "query") {
      textarea = queryRef.current;
    }

    const cursorPos = textarea?.selectionStart || 0;
    const currentQuery = selectedData[type] || "";
    const newQuery = currentQuery.slice(0, cursorPos) + value + currentQuery.slice(cursorPos);

    // Update states
    setEditData((prev) => ({ ...prev, [type]: newQuery }));
    setSelectedData((prev) => (prev ? { ...prev, [type]: newQuery } : null));

    // Maintain focus and cursor position
    setTimeout(() => {
      if (textarea) {
        textarea.focus();
        textarea.setSelectionRange(cursorPos + value.length, cursorPos + value.length);
      }
    }, 0);
  };

  React.useEffect(() => {
    if (!isTesting) {
      setTestMonitor(null);
    }
  }, [isTesting]);

  React.useEffect(() => {
    if (data && data.pages.length > 0) {
      setFlattenedData(data.pages.flatMap((page) => page.data.results));
    }
  }, [data]);

  React.useEffect(() => {
    if (!isAutoNaming || !selectedData) return;

    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    // Generate name based on monitor configuration
    const autoGeneratedName = formatMonitorScope(
      editData.area_names || selectedData.area_names,
      editData.categories_name || selectedData.categories_name,
    );
    if (autoGeneratedName === "" || autoGeneratedName === selectedData?.name) return;
    // Update the name
    setEditData((prev) => ({ ...prev, name: autoGeneratedName }));
    setSelectedData((prev) => (prev ? { ...prev, name: autoGeneratedName } : null));
  }, [isAutoNaming, selectedData?.area_names, selectedData?.categories_name, editData.area_names, editData.categories_name]);

  return (
    <div className="sticky top-0 z-10 flex flex-col h-[calc(100vh-90px)]">
      <div className="flex justify-between items-center py-3 flex-wrap gap-3">
        <div className="flex items-center gap-2">
          <h2 className="text-sm font-bold">Monitors</h2>
          <div className="relative w-[280px]">
            <Input
              type="text"
              placeholder="Search monitors..."
              className="w-full"
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
        <Button
          variant="outline"
          onClick={() => {
            setIsEditing(true);
            dialogDataDisplay(-1);
          }}
        >
          Add Monitor
        </Button>
      </div>
      {isLoading ? (
        <CenterPreloader />
      ) : (
        <Table className="rounded-md border-border w-full h-10 overflow-clip relative" divClassname="flex-1 overflow-auto customScrollbar2">
          <TableHeader className="sticky w-full top-0 h-10 border-b-2 border-border rounded-t-md bg-background">
            <TableRow>
              <TableHead>Action</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Query</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Urls</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {flattenedData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <div className="flex gap-1">
                    <HoverTooltip content="Edit monitors for this area">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit"
                        onClick={() => {
                          dialogDataDisplay(row.id);
                          setIsEditing(true);
                        }}
                      >
                        <PencilIcon className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                    <HoverTooltip content="Run a test with this monitor">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit"
                        onClick={() => {
                          setTestMonitor(row);
                          setIsTesting(true);
                        }}
                      >
                        <CirclePlay className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                    <HoverTooltip content="Delete monitor">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit text-destructive"
                        onClick={() => {
                          setSelectedRowId(row.id);
                          handleDeleteDialog(true);
                        }}
                      >
                        <Trash className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                  </div>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.query}</TableCell>
                <TableCell>{row.categories_name?.join(", ") || "-"}</TableCell>
                <TableCell>{row.urls.join(", ")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Dialog open={isEditing} onOpenChange={handleEditDialog}>
        <DialogContent className="max-w-2xl max-h-[100vh] md:max-h-[90vh] overflow-hidden flex flex-col">
          <DialogHeader>
            <DialogTitle>Edit Selected Row</DialogTitle>
            <DialogDescription />
          </DialogHeader>
          {selectedData ? (
            <div className="flex-1 overflow-y-auto customScrollbar2 p-3">
              <div className="grid gap-4">
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Label htmlFor="name">Name</Label>
                    <AirvanaTooltip value="Name of the monitor" key="MonitorName">
                      <Info className="w-3 h-3" />
                    </AirvanaTooltip>
                  </div>
                  <div className="flex items-center gap-2">
                    <Input
                      id="name"
                      value={selectedData?.name || ""}
                      autoComplete="off"
                      onChange={(e) => {
                        if (!selectedData) return;
                        setEditData({ ...editData, name: e.target.value });
                        setSelectedData({ ...selectedData, name: e.target.value });
                      }}
                      placeholder={selectedRowId ? "Names" : ""}
                      disabled={isAutoNaming}
                    />
                    <AirvanaTooltip value={isAutoNaming ? "Disable auto naming" : "Enable auto naming"}>
                      <div>
                        <Switch
                          checked={isAutoNaming}
                          onCheckedChange={handleAutoNamingChange}
                          className="data-[state=checked]:bg-accent"
                        />
                      </div>
                    </AirvanaTooltip>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Label htmlFor="name">Area</Label>
                    <AirvanaTooltip value="Pick an area where the monitor will search for results" key="MonitorArea">
                      <Info className="w-3 h-3" />
                    </AirvanaTooltip>
                  </div>
                  <div className="flex gap-2 items-center">
                    <Input
                      id="name"
                      value={selectedData?.area_names ? selectedData.area_names.join(" / ") : ""}
                      disabled
                      autoComplete="off"
                      placeholder={selectedRowId ? "Areas" : ""}
                    />
                    <AreaInputForm
                      initialSelectedAreas={selectedData?.area_ids || []}
                      initialSelectedAreaNames={selectedData?.area_names || []}
                      handleAreaChange={({ areaIds, areaNames }) => {
                        setEditData({ ...editData, area_ids: areaIds, area_names: areaNames });
                        setSelectedData({ ...selectedData, area_ids: areaIds, area_names: areaNames });
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div className="flex flex-col">
                    <Label htmlFor="method" className="text-xs mb-1">
                      Method
                    </Label>
                    <Select
                      value={selectedData?.method || "standard"}
                      onValueChange={(value: string) => {
                        if (!selectedData) return;
                        setEditData({ ...editData, method: value as "standard" | "targeted" });
                        setSelectedData({ ...selectedData, method: value as "standard" | "targeted" });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a method" />
                      </SelectTrigger>
                      <SelectContent className="z-[1025]">
                        {METHODS.map((method) => (
                          <AirvanaTooltip value={method.description} key={method.value}>
                            <SelectItem value={method.value}>{method.label}</SelectItem>
                          </AirvanaTooltip>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="flex flex-col">
                    <Label htmlFor="fileType" className="text-xs mb-1">
                      File Type
                    </Label>
                    <Select
                      value={selectedData?.file_type || "-1"}
                      onValueChange={(value: string) => {
                        if (!selectedData) return;
                        setEditData({ ...editData, file_type: value === "-1" ? "" : value });
                        setSelectedData({ ...selectedData, file_type: value === "-1" ? "" : value });
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a file type" className="placeholder:text-gray-500" />
                      </SelectTrigger>
                      <SelectContent className="z-[1025]">
                        <SelectItem value="-1">None</SelectItem>
                        {FILE_TYPES.map((fileType) => (
                          <AirvanaTooltip value={fileType.description} key={fileType.value}>
                            <SelectItem value={fileType.value}>{fileType.label}</SelectItem>
                          </AirvanaTooltip>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                      <Label htmlFor="query">Query</Label>
                      <AirvanaTooltip value="Query of the monitor" key="MonitorQuery">
                        <Info className="w-3 h-3" />
                      </AirvanaTooltip>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" size="icon" className="h-6 w-6">
                            <Variable className="w-3 h-3" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent side="right" align="start" className="z-[1025]">
                          {QUERY_VARIABLES.map((variable) => (
                            <DropdownMenuItem key={variable.value} onSelect={() => handleVariableInsert("query", variable.value)}>
                              {variable.label}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </div>
                  <Textarea
                    id="query"
                    ref={queryRef}
                    value={selectedData?.query || ""}
                    autoComplete="off"
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, query: e.target.value });
                      setSelectedData({ ...selectedData, query: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a query" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Label htmlFor="hq">High Quality Terms</Label>
                    <AirvanaTooltip
                      value="Terms that help filter for higher quality results. Adding terms like 'official', 'report', or 'study' can help focus on more authoritative sources"
                      key="MonitorHQ"
                    >
                      <Info className="w-3 h-3" />
                    </AirvanaTooltip>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon" className="h-6 w-6">
                          <Variable className="w-3 h-3" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="start" className="z-[1025]">
                        {QUERY_VARIABLES.map((variable) => (
                          <DropdownMenuItem key={variable.value} onSelect={() => handleVariableInsert("hq", variable.value)}>
                            {variable.label}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <Textarea
                    id="hq"
                    rows={1}
                    ref={hqRef}
                    value={selectedData?.hq || ""}
                    className="min-h-[30px]"
                    autoComplete="off"
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, hq: e.target.value });
                      setSelectedData({ ...selectedData, hq: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a High Quality Terms" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Label htmlFor="exactTerms">Exact Terms</Label>
                    <AirvanaTooltip
                      value="Terms that must appear exactly in the search results. Enter the exact phrase you want to match (e.g., 'short term rental') - results must contain this phrase exactly"
                      key="MonitorExactTerms"
                    >
                      <Info className="w-3 h-3" />
                    </AirvanaTooltip>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon" className="h-6 w-6">
                          <Variable className="w-3 h-3" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="start" className="z-[1025]">
                        {QUERY_VARIABLES.map((variable) => (
                          <DropdownMenuItem key={variable.value} onSelect={() => handleVariableInsert("exact_terms", variable.value)}>
                            {variable.label}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                  <Textarea
                    id="exactTerms"
                    rows={1}
                    ref={exactTermsRef}
                    value={selectedData?.exact_terms || ""}
                    className="min-h-[30px]"
                    autoComplete="off"
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, exact_terms: e.target.value });
                      setSelectedData({ ...selectedData, exact_terms: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a Exact Terms" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Label htmlFor="excludeTerms">Exclude Terms</Label>
                    <AirvanaTooltip
                      value="Terms that will be excluded from search results. Enter terms to exclude (e.g., 'pdf doc reviews') - results containing any of these terms will be filtered out"
                      key="MonitorExcludeTerms"
                    >
                      <Info className="w-3 h-3" />
                    </AirvanaTooltip>
                  </div>
                  <Input
                    id="excludeTerms"
                    value={selectedData?.exclude_terms || ""}
                    autoComplete="off"
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, exclude_terms: e.target.value });
                      setSelectedData({ ...selectedData, exclude_terms: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a Exclude Terms" : ""}
                  />
                </div>

                <div className="space-y-2">
                  <div className="grid grid-cols-[auto_1fr] gap-3">
                    <div className="flex items-center gap-2">
                      <div className="flex items-center gap-2">
                        <Label htmlFor="filter">Duplicate Filter</Label>
                        <AirvanaTooltip value="Filter out duplicate results" key="MonitorDuplicateFilter">
                          <Info className="w-3 h-3" />
                        </AirvanaTooltip>
                      </div>
                      <Checkbox
                        id="filter"
                        checked={selectedData?.duplicate_filter || false}
                        onCheckedChange={(e) => {
                          if (!selectedData) return;
                          setEditData({ ...editData, duplicate_filter: e as boolean });
                          setSelectedData({ ...selectedData, duplicate_filter: e as boolean });
                        }}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="flex items-center gap-2">
                        <Label htmlFor="num">Number</Label>
                        <AirvanaTooltip value="Number of results to return" key="MonitorNumOfResults">
                          <Info className="w-3 h-3" />
                        </AirvanaTooltip>
                      </div>
                      <Input
                        id="num"
                        value={selectedData?.num_of_results || ""}
                        onChange={(e) => {
                          if (!selectedData) return;
                          setEditData({ ...editData, num_of_results: parseInt(e.target.value, 10) });
                          setSelectedData({ ...selectedData, num_of_results: parseInt(e.target.value, 10) });
                        }}
                        placeholder={selectedRowId ? "Enter a Number" : ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <UrlInputForm
                    urls={selectedData?.urls || []}
                    onUrlsChange={(urls) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, urls });
                      setSelectedData({ ...selectedData, urls });
                    }}
                  />
                </div>
                <div className="space-y-2">
                  <DateRestrictForm
                    initialDateRestrict={selectedData?.date_restrict}
                    onDateRestrictChange={(dateRestrict) => {
                      if (!selectedData) return;
                      if (dateRestrict !== selectedData?.date_restrict) {
                        setEditData({ ...editData, date_restrict: dateRestrict });
                        setSelectedData({ ...selectedData, date_restrict: dateRestrict });
                      }
                    }}
                    load={loadDateRestrict}
                    setLoad={setLoadDateRestrict}
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="categories" className="text-xs">
                    Categories
                  </Label>
                  <MultiSelect
                    multiple={true as any}
                    options={categories}
                    value={categories ? categories.filter((cat) => selectedData?.categories?.includes(cat.value)) : []}
                    onChange={(values: SelectOption[]) => {
                      if (!selectedData) return;
                      setEditData({
                        ...editData,
                        categories: values.map((v) => Number(v.value)),
                      });
                      setSelectedData({
                        ...selectedData,
                        categories: values.map((v) => Number(v.value)),
                        categories_name: values.map((v) => v.label),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center p-4">
              <div className="flex flex-col items-center gap-2">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
                <p className="text-sm text-muted-foreground">Loading monitor data...</p>
              </div>
            </div>
          )}
          <DialogFooter className="flex flex-col gap-2">
            <div className="flex gap-2">
              <ExistingMonitorForm
                name="Choice Template"
                isOpen={isTemplate}
                setIsOpen={setIsTemplate}
                onSelectMonitors={(monitors) => {
                  //set monitor into selectedData except for the id
                  const { id, showDetails, ...rest } = monitors[0];
                  setEditData({
                    ...editData,
                    ...rest,
                  });
                  setSelectedData({
                    ...selectedData,
                    ...rest,
                    id: selectedData?.id || -1,
                  });
                  setLoadDateRestrict(true);
                }}
                currentMonitors={[]}
                singleSelect
              />
              <Button
                className="w-full"
                variant="outline"
                onClick={() => {
                  setIsTesting(true);
                  setTestMonitor(selectedData);
                }}
              >
                Test Monitor
              </Button>
            </div>
            <div className="flex gap-2">
              <Button variant="outline" className="w-full" onClick={() => handleEditDialog(false)}>
                Cancel
              </Button>
              <Button className="w-full" onClick={handleSave}>
                Save Changes
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isDeleting} onOpenChange={handleDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Monitor</DialogTitle>
            <DialogDescription className="text-destructive text-sm mt-3">Are you sure you want to delete this monitor?</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => handleDeleteDialog(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={() => deleteMonitor(selectedRowId)}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <TestMonitor isTesting={isTesting} setIsTesting={setIsTesting} testMonitor={testMonitor || undefined} />
    </div>
  );
}

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { RefreshCw } from "lucide-react";

import { cn } from "src/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-[10px] text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default:
          "bg-tailwind_primary text-tailwind_primary_foreground hover:bg-tailwind_primary/80 active:scale-95 transition-all duration-200 active:bg-tailwind_primary/70",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90 active:scale-95 transition-all duration-200 active:bg-destructive/70",
        outline: "border border-input bg-background hover:bg-foreground/5 hover:text-foreground active:bg-foreground/10",
        secondary:
          "bg-tailwind_secondary text-tailwind_secondary_foreground hover:bg-tailwind_secondary/80 active:bg-tailwind_secondary/80",
        ghost: "text-foreground hover:text-primary active:text-primary/80 rounded-none",
        link: "text-primary underline-offset-4 hover:underline",
        transparent: "text-foreground hover:bg-primary/5 active:bg-primary/10",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 px-3",
        lg: "h-11 px-8 text-base",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, loading = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), loading && "opacity-50")}
        disabled={loading || props.disabled}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };

import { useState, useEffect, useRef, useCallback } from "react";

export type SocketStatus = "connected" | "disconnected" | "connecting";

interface UseSocketReturn {
  status: SocketStatus;
  sendMessage: (message: string) => void;
  socket: WebSocket | null;
  onMessage: (callback: (message: string) => void) => void;
  error: Event | null;
}

export const useSocket = (url: string): UseSocketReturn => {
  const [status, setStatus] = useState<SocketStatus>("disconnected");
  const messageCallbackRef = useRef<(message: string) => void>(() => {});
  const [error, setError] = useState<Event | null>(null);
  const socketRef = useRef<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    setStatus("connecting");
    const ws = new WebSocket(url);

    ws.onopen = () => {
      setStatus("connected");
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };

    ws.onclose = () => {
      setStatus("disconnected");
      reconnectTimeoutRef.current = window.setTimeout(() => {
        ws.close();
        socketRef.current = new WebSocket(url);
      }, 5000);
    };

    ws.onerror = (event) => {
      setError(event);
      setStatus("disconnected");
    };

    ws.onmessage = (event) => {
      messageCallbackRef.current(event.data);
    };

    socketRef.current = ws;

    return () => {
      ws.close();
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [url]);

  const sendMessage = useCallback((message: string) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    }
  }, []);

  const onMessage = useCallback((callback: (message: string) => void) => {
    messageCallbackRef.current = callback;
  }, []);

  return {
    status,
    sendMessage,
    socket: socketRef.current,
    onMessage,
    error,
  };
};

/** @jsxImportSource @emotion/react */
import React from "react";
import { useAuthStore } from "src/store/account";
import { useProfileStoreWithRefetch } from "src/hooks/profile";
import { useThemedCss } from "src/hooks/styles";
import Button from "src/components/ui/form/button";
import Text from "src/components/ui/text";
import { useTranslation } from "react-i18next";
import styles, { IStyles } from "src/components/ui/menu/ProfileMenu.styles";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar";
import { Link } from "react-router-dom";
import { useBreakpointIsLowerOrEqual } from "src/hooks/responsive";
import { useLogout } from "src/hooks/account";
import { User, Pin, Users, CreditCard, AlertCircle, LogOut, UserRound, Settings } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "src/components/ui/dropdown-menu";
import { useStatus } from "src/provider/StatusProvider";

const ProfileMenu: React.FC = () => {
  const { t } = useTranslation();
  const profile = useProfileStoreWithRefetch();
  const themedCss = useThemedCss<IStyles>(styles);
  const isMobile = useBreakpointIsLowerOrEqual("sm");
  const handleLogout = useLogout();
  const { accessToken, isAuthenticated } = useAuthStore();

  const buttonSize = React.useMemo(() => {
    if (isMobile) {
      return "sm";
    }
    return undefined;
  }, [isMobile]);

  React.useEffect(() => {
    if (isAuthenticated && !accessToken) {
      handleLogout();
    }
  }, [isAuthenticated, accessToken, handleLogout]);

  if (!isAuthenticated) {
    return (
      <div className="d-inline-flex flex-nowrap justify-content-end align-items-center">
        <Text.Link to="/login/">
          <Button variant="outline-primary" size={buttonSize} className="me-2 me-md-3">
            {t("Sign In")}
          </Button>
        </Text.Link>
        <Text.Link to="/sign-up/">
          <Button size={buttonSize} variant="primary">
            {t("Sign Up")}
          </Button>
        </Text.Link>
      </div>
    );
  }

  const { onlineStatus } = useStatus();
  return (
    <div className="d-flex flex-row align-items-center">
      <div css={themedCss.button}>
        <span css={themedCss.name}>{profile.name}</span>
        <span css={themedCss.email}>{profile.email}</span>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="relative">
            <Avatar className="select-none rounded-md">
              <AvatarImage src={profile.icon ?? ""} alt="Profile" className="object-cover aspect-square" />
              <AvatarFallback className="font-bold text-xl">
                {profile.name ? (
                  profile.name
                    .split(/\s+/)
                    .map((part) => part[0])
                    .join("")
                    .slice(0, 2)
                ) : (
                  <UserRound className="h-6 w-6" />
                )}
              </AvatarFallback>
            </Avatar>
            <span
              className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-2 border-background ${
                onlineStatus ? "bg-green-500" : "bg-gray-400"
              }`}
            />
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end" className="w-48 z-[9999]">
          <DropdownMenuItem asChild>
            <Link to="/settings/profile/" className="hover:bg-foreground/5 text-foreground hover:text-foreground">
              <User size={16} className="mr-2" />
              {t("Profile")}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/saved-search/" className="hover:bg-foreground/5 text-foreground hover:text-foreground">
              <Pin size={16} className="mr-2" />
              {t("Saved searches")}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/my-people/" className="hover:bg-foreground/5 text-foreground hover:text-foreground">
              <Users size={16} className="mr-2" />
              {t("Vendors")}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/settings/billing/" className="hover:bg-foreground/5 text-foreground hover:text-foreground">
              <CreditCard size={16} className="mr-2" />
              {t("Billing")}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link to="/settings/" className="hover:bg-foreground/5 text-foreground hover:text-foreground">
              <Settings size={16} className="mr-2" />
              {t("Settings")}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <a
              href="https://form.asana.com/?k=dt778Q_1GFSJeNPw7dr52g&d=1202851120158061"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:bg-foreground/5 text-foreground hover:text-foreground"
            >
              <AlertCircle size={16} className="mr-2" />
              {t("Submit an error")}
            </a>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleLogout} className="hover:bg-foreground/5 text-foreground hover:text-foreground">
            <LogOut size={16} className="mr-2" />
            {t("Log out")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ProfileMenu;

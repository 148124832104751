import { makeRequest } from "src/api/request";

export const getDashboardData = async () =>
  await makeRequest.get<AdminAPI.LocalPulse.Dashboard.Response>("/admin/local-pulse/local-monitors/dashboard/");

export const getLocalMonitorList = async (params?: AdminAPI.LocalPulse.List.Request) =>
  await makeRequest.get<AdminAPI.LocalPulse.List.Response>("/admin/local-pulse/local-monitors/", { params });

export const createLocalMonitor = async (data: AdminAPI.LocalPulse.CreateLocalMonitor.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.CreateLocalMonitor.Response>("/admin/local-pulse/local-monitors/create-monitor/", { data });

export const createNewMonitor = async (data: AdminAPI.LocalPulse.CreateNewMonitor.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.CreateNewMonitor.Response>("/admin/local-pulse/monitors/create-monitor/", data);

export const getLocalMonitorConfigBulkList = async (data?: AdminAPI.LocalPulse.monitorConfigBulkList.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.monitorConfigBulkList.Response>("/admin/local-pulse/monitors/bulk-get/", data);

export const getLocalMonitors = async (data?: AdminAPI.LocalPulse.GetLocalMonitors.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.GetLocalMonitors.Response>("/admin/local-pulse/local-monitors/get-monitors/", data);

export const getMonitorsList = async (params?: AdminAPI.LocalPulse.monitorConfigList.Request) =>
  await makeRequest.get<AdminAPI.LocalPulse.monitorConfigList.Response>("/admin/local-pulse/monitors/", { params });

export const updateMonitorConfig = async (data: AdminAPI.LocalPulse.UpdateMonitorConfig.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.UpdateMonitorConfig.Response>("/admin/local-pulse/monitors/monitor-update/", data);

export const runLocalMonitorTest = async (data: AdminAPI.LocalPulse.RunLocalMonitorTest.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.RunLocalMonitorTest.Response>("/admin/local-pulse/local-monitors/test-local-monitor/", data);

export const runMonitorTest = async (data: AdminAPI.LocalPulse.RunMonitorTest.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.RunMonitorTest.Response>("/admin/local-pulse/monitors/test-monitor/", data);

export const updateLocalMonitorStatus = async (data: AdminAPI.LocalPulse.UpdateLocalMonitor.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.UpdateLocalMonitor.Response>("/admin/local-pulse/local-monitors/update-local-monitor/", data);

export const runPreloadMonitors = async (data: AdminAPI.LocalPulse.RunPreloadMonitors.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.RunPreloadMonitors.Response>(
    "/admin/local-pulse/local-monitors/preload-monitor/",
    data,
    { timeout: 3600000 }, // 1 hour timeout (3600000 ms = 60 * 60 * 1000)
  );

export const runDailyMonitors = async (data: AdminAPI.LocalPulse.RunDailyMonitors.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.RunDailyMonitors.Response>(
    "/admin/local-pulse/local-monitors/run-daily-monitor/",
    data,
    { timeout: 3600000 }, // 1 hour timeout (3600000 ms = 60 * 60 * 1000)
  );

export const deleteMonitorConfig = async (data: AdminAPI.LocalPulse.DeleteMonitorConfig.Request) =>
  await makeRequest.post<AdminAPI.LocalPulse.DeleteMonitorConfig.Response>("/admin/local-pulse/monitors/delete-monitor/", data);

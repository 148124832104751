"use client";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { AxiosResponse } from "axios";
import { useInView } from "react-intersection-observer";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "src/components/ui/select";
import { createLocalMonitor, updateLocalMonitorStatus } from "src/api/admin/local-pulse";
import { CirclePlay, PencilIcon, RefreshCcw } from "lucide-react";
import { InfiniteData } from "@tanstack/react-query";
import { HoverTooltip } from "src/components/ui/hoverTooltip";
import { Switch } from "src/components/ui/switch";
import { CenterPreloader } from "src/components/loaders";
import CustomDialogContent from "src/pages/admin/local-pulse/local-monitor/CustomDialogContent";
import TestMonitor from "src/pages/admin/local-pulse/local-monitor/TestMonitor";

interface EditableTableProps {
  data: InfiniteData<AxiosResponse<AdminAPI.LocalPulse.List.Response, any>> | undefined;
  isLoading: boolean;
  stateData: AxiosResponse<API.Area.List.Response, any> | undefined;
  filter: Partial<AdminAPI.LocalPulse.List.Filter>;
  handleFilterChange: (name: string, value: string) => void;
  search: string;
  setSearch: (search: string) => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
}

export type ParamsType = "Status";

export default function EditableTable({
  data: initialData,
  stateData,
  filter,
  handleFilterChange,
  search,
  setSearch,
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  refetch,
}: EditableTableProps) {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [editMonitors, setEditMonitors] = useState<AdminAPI.LocalPulse.MonitorConfig[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [testAreaId, setTestAreaId] = useState<number | null>(null);
  const [data, setData] = useState<AdminAPI.LocalPulse.LocalMonitorData[]>([]);
  const [tempSearch, setTempSearch] = useState(search);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [error, setError] = useState({
    id: 0,
    urls: false,
    query: false,
  });

  const toggleRow = (id: number) => {
    const newSelected = new Set(selectedRows);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelectedRows(newSelected);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = useCallback(async () => {
    if (editMonitors && editMonitors.length > 0) {
      // Validate all monitors in a single pass
      const errorMonitor = editMonitors.find((monitor) => !monitor?.query);

      if (errorMonitor) {
        setError({
          id: errorMonitor.id || 0,
          query: !errorMonitor.query,
          urls: false,
        });
        return;
      }
    }

    const newData = data.map((row) => {
      if (selectedRows.has(row.area_id)) {
        return {
          ...row,
          monitors_name: editMonitors.map((monitor) => monitor.name || ""),
          monitors: editMonitors.map((monitor) => String(monitor.id) || ""),
        };
      }
      return row;
    });

    setIsEditing(false);
    setSelectedRows(new Set());
    setData(newData);
    // Serialize for all the empty urls
    const serializedMonitors = editMonitors.map((monitor) => ({
      ...monitor,
      urls: monitor.urls.filter((url) => url.trim()),
    }));

    const response = await createLocalMonitor({
      area_ids: Array.from(selectedRows).map((id) => Number(id)),
      monitors: serializedMonitors || [],
    });
  }, [editMonitors, data, selectedRows, setError, setIsEditing, setSelectedRows, setData]);

  const handleStatusChange = async (areaId: number[], isActive: boolean) => {
    try {
      const newData = data.map((item) => (areaId.includes(item.area_id) ? { ...item, is_active: isActive } : item));
      setData(newData);
      const response = await updateLocalMonitorStatus({
        area_ids: areaId,
        is_active: isActive,
      });
    } catch (statusError) {
      console.error("Error updating monitor status:", statusError);
    }
  };

  const handleParamsChange = (type: ParamsType, value: any, areaId: number[]) => {
    if (type === "Status") {
      handleStatusChange(areaId, value);
    }

    setIsEditing(false);
    setSelectedRows(new Set());
  };

  // Add this helper function before the return statement
  const getSelectedAreaNames = () => {
    if (isEditing) {
      const selectedAreas = data
        .filter((row) => selectedRows.has(row.area_id))
        .map((row) => row.area_name)
        .join("\n• ");
      return selectedAreas ? `• ${selectedAreas}` : "";
    }
    return "";
  };

  const debouncedSetSearch = useMemo(
    () =>
      debounce((value: string) => {
        setSearch(value);
      }, 500),
    [], // Empty dependency array since setSearch is stable
  );

  useEffect(() => {
    debouncedSetSearch.cancel();
  }, [debouncedSetSearch]);

  useEffect(() => {
    if (initialData) {
      const allData = initialData.pages.reduce<AdminAPI.LocalPulse.LocalMonitorData[]>(
        (acc, page) => [...acc, ...(page?.data.results || [])],
        [],
      );
      setData(allData);
    }
  }, [initialData]);

  useEffect(() => {
    if (isFetchingNextPage) {
      setShowPlaceholder(true);
    } else if (showPlaceholder) {
      // Keep placeholder visible for 500ms after loading completes
      const timer = setTimeout(() => {
        setShowPlaceholder(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isFetchingNextPage]);

  const { ref, inView } = useInView();

  // Add this effect to trigger next page load
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <div className="sticky top-0 z-10 flex flex-col h-[calc(100vh-90px)]">
      <div className="bg-background flex justify-between items-center py-3 flex-wrap gap-2">
        <h2 className="text-sm font-bold">Local Monitor Feed</h2>
        <div className="relative w-[280px]">
          <input
            type="text"
            placeholder="Search areas..."
            className="w-full h-9 rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
            onChange={(e) => {
              setTempSearch(e.target.value);
              debouncedSetSearch(e.target.value);
            }}
            value={tempSearch}
          />
        </div>
        <Select
          value={filter.level?.toString() || ""}
          onValueChange={(value) => {
            // If user selects "none" or the same value, clear the selection
            if (value === "none" || value === filter.level?.toString()) {
              handleFilterChange("level", "");
            } else {
              handleFilterChange("level", value);
            }
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select Level" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Level</SelectLabel>
              <SelectItem value="none">Select Level</SelectItem>
              <SelectItem value="3">City</SelectItem>
              <SelectItem value="2">County</SelectItem>
              <SelectItem value="1">State</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <Select
          value={filter.state?.toString() || ""}
          onValueChange={(value) => {
            // If user selects "none" or the same value, clear the selection
            if (value === "none" || value === filter.state?.toString()) {
              handleFilterChange("state", "");
            } else {
              handleFilterChange("state", value);
            }
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select a state" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>States</SelectLabel>
              <SelectItem value="none">Select a state</SelectItem>
              {stateData?.data?.map((state) => (
                <SelectItem key={state.id} value={state.id.toString()}>
                  {state.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            // Force a refetch of the data
            refetch();
          }}
        >
          <RefreshCcw className="w-4 h-4" />
        </Button>
        <Dialog open={isEditing} onOpenChange={setIsEditing}>
          <DialogTrigger asChild>
            <Button onClick={handleEdit} disabled={selectedRows.size === 0} variant="default" size="sm">
              Edit {selectedRows.size} Selected
            </Button>
          </DialogTrigger>
          <DialogContent className="h-[100vh] max-h-[100vh] md:h-auto md:max-h-[90vh] overflow-hidden flex flex-col">
            <DialogHeader>
              <DialogTitle>Edit {selectedRows.size === 1 ? "Row" : "Multiple Rows"}</DialogTitle>
              <DialogDescription />
            </DialogHeader>
            <CustomDialogContent
              initialAreaIds={Array.from(selectedRows)}
              onQueriesChange={(queries) => {
                setEditMonitors(queries);
              }}
              currentData={data.filter((row) => selectedRows.has(row.area_id))}
              error={error}
              setError={setError}
              displayAreaName={selectedRows.size >= 1 ? `${getSelectedAreaNames()}` : ""}
              handleSave={handleSave}
              onParamsChange={handleParamsChange}
              setIsEditing={setIsEditing}
            />
          </DialogContent>
        </Dialog>
      </div>
      {isLoading ? (
        <CenterPreloader />
      ) : (
        <Table className="rounded-md border-border w-full h-10 overflow-clip relative" divClassname="flex-1 overflow-auto customScrollbar2">
          <TableHeader className="sticky w-full top-0 h-10 border-b-2 border-border rounded-t-md bg-background z-[999]">
            <TableRow>
              <TableHead>
                <Checkbox
                  checked={selectedRows.size === data.length}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedRows(new Set(data.map((row) => row.area_id)));
                    } else {
                      setSelectedRows(new Set());
                    }
                  }}
                />
              </TableHead>
              <TableHead>Area</TableHead>
              <TableHead>Actions</TableHead>
              <TableHead>Monitors</TableHead>
              <TableHead>Status</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="max-h-[600px] overflow-auto">
            {data.map((row) => (
              <TableRow key={row.area_id}>
                <TableCell>
                  <Checkbox checked={selectedRows.has(row.area_id)} onCheckedChange={() => toggleRow(row.area_id)} />
                </TableCell>
                <TableCell>{row.area_name}</TableCell>
                <TableCell>
                  <div className="flex gap-1">
                    <HoverTooltip content="Edit monitors for this area">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit"
                        onClick={() => {
                          setSelectedRows(new Set([row.area_id]));
                          setIsEditing(true);
                        }}
                      >
                        <PencilIcon className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                    <HoverTooltip content="Run a test with this area">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit"
                        onClick={() => {
                          setIsTesting(true);
                          setTestAreaId(row.area_id);
                        }}
                        disabled={!row.monitors_name.length}
                      >
                        <CirclePlay className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                  </div>
                </TableCell>
                <TableCell>{row.monitors_name.join(", ") || ""}</TableCell>
                <TableCell>
                  <Switch
                    checked={row.is_active}
                    onCheckedChange={(checked) => {
                      handleStatusChange([row.area_id], checked);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            {(isFetchingNextPage || showPlaceholder) && (
              <TableRow>
                <TableCell colSpan={5} className="text-center py-4">
                  {isFetchingNextPage ? "Loading more..." : "Processing..."}
                </TableCell>
              </TableRow>
            )}
            <TableRow ref={ref}>
              <TableCell colSpan={5} className="h-4" />
            </TableRow>
          </TableBody>
        </Table>
      )}
      <TestMonitor isTesting={isTesting} setIsTesting={setIsTesting} testAreaId={testAreaId || undefined} />
    </div>
  );
}

import React from "react";
import { Navigate, RouteObject } from "react-router-dom";
import ErrorPage from "src/pages/ErrorPage";
import Layout from "src/pages/Layout";
import lazyWithRetry from "src/utils/lazy-with-retry";
import AgentWrapper from "src/pages/agent/AgentWrapper";
import AgentRequiredWrapper from "src/pages/agent/AgentRequiredWrapper";
import marketCampaignRouter from "src/pages/agent/market-campaigns/router";
import strVerificationRouter from "src/pages/agent/str-verification/router";
import agentsRouter from "src/pages/agent/agents/router";

const AgentDashboardPage = lazyWithRetry(() => import("src/pages/agent/AgentDashboardPage"));
const PropertyTablePage = lazyWithRetry(() => import("src/pages/admin/properties/table-page"));
const AgentOverviewPage = lazyWithRetry(() => import("src/pages/agent/AgentOverviewPage"));
const AgentSubmitPropertyPage = lazyWithRetry(() => import("src/pages/agent/AgentSubmitPropertyPage"));

const adminRoutes: RouteObject = {
  path: "/agent/",
  element: <Layout />,
  errorElement: <ErrorPage />,
  children: [
    {
      element: <AgentWrapper />,
      children: [
        {
          path: "common/",
          element: (
            <AgentRequiredWrapper>
              <AgentDashboardPage />
            </AgentRequiredWrapper>
          ),
          children: [
            {
              index: true,
              element: (
                <AgentRequiredWrapper>
                  <AgentOverviewPage />
                </AgentRequiredWrapper>
              ),
            },
            {
              path: "submit-property/",
              element: (
                <AgentRequiredWrapper>
                  <AgentSubmitPropertyPage />
                </AgentRequiredWrapper>
              ),
            },
          ],
        },
        ...agentsRouter,
        ...marketCampaignRouter,
        ...strVerificationRouter,
        {
          path: "property-table/",
          children: [
            {
              index: true,
              element: (
                <AgentRequiredWrapper>
                  <PropertyTablePage baseUrl="/agent/property-table/" />
                </AgentRequiredWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <AgentRequiredWrapper>
                  <PropertyTablePage baseUrl="/agent/property-table/" />
                </AgentRequiredWrapper>
              ),
            },
          ],
        },
        {
          path: "keyword-verification-table/",
          children: [
            {
              index: true,
              element: (
                <AgentRequiredWrapper>
                  <PropertyTablePage pageTitle="Keyword validation" keywordValidationOnly baseUrl="/agent/keyword-verification-table/" />
                </AgentRequiredWrapper>
              ),
            },
            {
              path: ":page/",
              element: (
                <AgentRequiredWrapper>
                  <PropertyTablePage pageTitle="Keyword validation" keywordValidationOnly baseUrl="/agent/keyword-verification-table/" />
                </AgentRequiredWrapper>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "",
      element: <Navigate to="/agent/common/" />,
    },
  ],
};

export default adminRoutes;

import { AxiosError, AxiosResponse } from "axios/index";
import { useMutation, UseMutationResult, useQuery } from "@tanstack/react-query";
import {
  updateProperty,
  getProperty,
  updatePropertyContact,
  getVerificationRequiredPropertyList,
  verifyProperty,
  getPropertyTableList,
  rejectProperty,
  patchTableProperty,
  postPropertyTableExport,
  getPropertyContactConversationList,
  postPropertyContactConversationCreate,
  updatePartialPropertyContact,
  updatePropertyInfo,
  deleteProperty,
} from "src/api/admin/property";
import { type UseMutationOptions, UseQueryResult } from "@tanstack/react-query/src/types";

type IUseUpdatePropertyRequest = (
  id: AdminAPI.Property.PropertyData["id"],
) => UseMutationResult<
  AxiosResponse<AdminAPI.Property.Update.Response>,
  AxiosError<AdminAPI.Property.Update.ErrorResponse>,
  AdminAPI.Property.Update.Request
>;

export const useUpdatePropertyRequest: IUseUpdatePropertyRequest = (id) =>
  useMutation({
    mutationFn: async (data) => await updateProperty(id, data),
  });

type IUseVerifyPropertyRequest = () => UseMutationResult<
  AxiosResponse<AdminAPI.Property.Verify.Response>,
  AxiosError<AdminAPI.Property.Verify.ErrorResponse>,
  AdminAPI.Property.PropertyData["id"]
>;

export const useVerifyPropertyRequest: IUseVerifyPropertyRequest = () =>
  useMutation({
    mutationFn: async (id) => await verifyProperty(id),
  });

type IUseRejectPropertyRequest = () => UseMutationResult<
  AxiosResponse<AdminAPI.Property.Reject.Response>,
  AxiosError<AdminAPI.Property.Reject.ErrorResponse>,
  AdminAPI.Property.Reject.Request & {
    id: AdminAPI.Property.PropertyData["id"];
  }
>;

export const useRejectPropertyRequest: IUseRejectPropertyRequest = () =>
  useMutation({
    mutationFn: async ({ id, ...data }) => await rejectProperty(id, data),
  });

type IUseUpdatePropertyContactRequest = (
  id: AdminAPI.Property.PropertyData["id"],
) => UseMutationResult<
  AxiosResponse<AdminAPI.Property.UpdateContact.Response>,
  AxiosError<AdminAPI.Property.UpdateContact.ErrorResponse>,
  AdminAPI.Property.UpdateContact.Request
>;

export const useUpdatePropertyContactRequest: IUseUpdatePropertyContactRequest = (id) =>
  useMutation({
    mutationFn: async (data) => await updatePropertyContact(id, data),
  });

type IUseUpdatePropertyInfoRequest = (
  id: AdminAPI.Property.PropertyData["id"],
) => UseMutationResult<
  AxiosResponse<AdminAPI.Property.UpdatePropertyInfo.Response>,
  AxiosError<AdminAPI.Property.UpdatePropertyInfo.ErrorResponse>,
  AdminAPI.Property.UpdatePropertyInfo.Request
>;

export const useUpdatePropertyInfoRequest: IUseUpdatePropertyInfoRequest = (id) =>
  useMutation({
    mutationFn: async (data) => await updatePropertyInfo(id, data),
  });

type IUseUpdatePartialPropertyContactRequest = (
  id: AdminAPI.Property.PropertyData["id"],
) => UseMutationResult<
  AxiosResponse<AdminAPI.Property.UpdateContact.Response>,
  AxiosError<AdminAPI.Property.UpdateContact.ErrorResponse>,
  Partial<AdminAPI.Property.UpdateContact.Request>
>;

export const useUpdatePartialPropertyContactRequest: IUseUpdatePartialPropertyContactRequest = (id) =>
  useMutation({
    mutationFn: async (data) => await updatePartialPropertyContact(id, data),
  });

type IUseGetPropertyRequest = (
  id: AdminAPI.Property.PropertyData["id"],
) => UseQueryResult<AxiosResponse<AdminAPI.Property.Get.Response>, AxiosError<AdminAPI.Property.Get.ErrorResponse>>;

export const useGetPropertyRequest: IUseGetPropertyRequest = (id) =>
  useQuery(["useGetPropertyRequest", id], async () => await getProperty(id));

type IUsePropertyVerificationRequiredListRequest = (
  data: AdminAPI.Property.VerificationRequiredList.Request,
) => UseQueryResult<
  AxiosResponse<AdminAPI.Property.VerificationRequiredList.Response>,
  AxiosError<AdminAPI.Property.VerificationRequiredList.ErrorResponse>
>;

export const usePropertyVerificationRequiredListRequest: IUsePropertyVerificationRequiredListRequest = (data) =>
  useQuery(["usePropertyVerificationRequiredListRequest", data], async () => await getVerificationRequiredPropertyList(data), {
    keepPreviousData: true,
  });

type IUsePropertyTableAdminRequest = (
  data: AdminAPI.Property.PropertyTable.Request,
) => UseQueryResult<AxiosResponse<AdminAPI.Property.PropertyTable.Response>, AxiosError<AdminAPI.Property.PropertyTable.ErrorResponse>>;

export const usePropertyTableAdminRequest: IUsePropertyTableAdminRequest = (data) =>
  useQuery(["usePropertyTableAdminRequest", data], async () => await getPropertyTableList(data), { keepPreviousData: true });

type IUsePropertyTableAdminExportRequest = () => UseMutationResult<
  AxiosResponse<AdminAPI.Property.PropertyTableExport.Response>,
  AxiosError<AdminAPI.Property.PropertyTableExport.ErrorResponse>,
  AdminAPI.Property.PropertyTableExport.Request
>;

export const usePropertyTableAdminExportRequest: IUsePropertyTableAdminExportRequest = () =>
  useMutation({
    mutationFn: async (data) => await postPropertyTableExport(data),
  });

type IUsePropertyTableAdminRemoveKeywordCategoryRequest = (
  id: AdminAPI.Property.PropertyData["id"],
  mutationData?: Omit<
    UseMutationOptions<
      AxiosResponse<AdminAPI.Property.UpdateTableProperty.Response>,
      AxiosError<AdminAPI.Property.UpdateTableProperty.ErrorResponse>,
      AdminAPI.Property.UpdateTableProperty.Request
    >,
    "mutationFn"
  >,
) => UseMutationResult<
  AxiosResponse<AdminAPI.Property.UpdateTableProperty.Response>,
  AxiosError<AdminAPI.Property.UpdateTableProperty.ErrorResponse>,
  AdminAPI.Property.UpdateTableProperty.Request
>;

export const usePropertyTableAdminUpdateRequest: IUsePropertyTableAdminRemoveKeywordCategoryRequest = (id, mutationData) =>
  useMutation({
    mutationFn: async (data) => await patchTableProperty(id, data),
    ...(mutationData || {}),
  });

type IUsePropertyContactConversationListRequest = (
  id: number | string,
  enabled?: boolean,
) => UseQueryResult<
  AxiosResponse<AdminAPI.Property.ConversationList.Response>,
  AxiosError<AdminAPI.Property.ConversationList.ErrorResponse>
>;

export const usePropertyContactConversationListRequest: IUsePropertyContactConversationListRequest = (id, enabled) =>
  useQuery(["usePropertyContactConversationListRequest", id.toString()], async () => await getPropertyContactConversationList(id), {
    enabled,
  });

type IUsePropertyCreateContactConversationRequest = (
  id: number | string,
) => UseMutationResult<
  AxiosResponse<AdminAPI.Property.ConversationCreate.Response> | AxiosResponse<AdminAPI.Property.ConversationCreate.Response>[],
  AxiosError<AdminAPI.Property.ConversationCreate.ErrorResponse> | AxiosError<AdminAPI.Property.ConversationCreate.ErrorResponse>[],
  AdminAPI.Property.ConversationCreate.Request | AdminAPI.Property.ConversationCreate.Request[] | FormData | FormData[]
>;

export const usePropertyCreateContactConversationRequest: IUsePropertyCreateContactConversationRequest = (id) =>
  useMutation({
    mutationFn: async (data) => {
      if (Array.isArray(data)) {
        return await Promise.all(data.map(async (item) => await postPropertyContactConversationCreate(id, item)));
      }
      return await postPropertyContactConversationCreate(id, data);
    },
  });

type IUseDeletePropertyRequest = () => UseMutationResult<
  AxiosResponse<AdminAPI.Property.Delete.Response>,
  AxiosError<AdminAPI.Property.Delete.ErrorResponse>,
  AdminAPI.Property.Delete.Request
>;

export const useDeletePropertyRequest: IUseDeletePropertyRequest = () =>
  useMutation({
    mutationFn: async (data) => await deleteProperty(data.id),
  });

import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getMonitorsList } from "src/api/admin/local-pulse";
import EditableTable from "src/pages/admin/local-pulse/monitor-config/EditableTable";

const MonitorParamsPage: React.FC = () => {
  // Fetch monitors with pagination
  const [search, setSearch] = React.useState("");
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: ["monitors", search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getMonitorsList({
        page: pageParam,
        limit: 50,
        search,
      });
      return response;
    },
    getNextPageParam: (lastPage, pages) => (lastPage.data.hasMore ? pages.length + 1 : undefined),
    staleTime: 1000 * 60, // Data becomes stale after 1 minute
    refetchOnWindowFocus: false, // Disable automatic refetch on window focus
    refetchOnMount: true,
    // Only refetch if the data is older than 5 minutes
    refetchInterval: 1000 * 60 * 5,
  });

  return (
    <div className="relative">
      <EditableTable data={data} search={search} handleSearch={setSearch} isLoading={isLoading} />
    </div>
  );
};

export default MonitorParamsPage;

import { makeRequest } from "src/api/request";

export const getProfile = async () => await makeRequest.get<API.Profile.Get.Response>("/accounts/profile/");

export const updateProfile = async (data: Partial<API.Profile.Update.Request>) =>
  await makeRequest.patch<API.Profile.Update.Response>("/accounts/profile/", data);

export const updatePassword = async (data: API.Profile.UpdatePassword.Request) =>
  await makeRequest.patch<API.Profile.UpdatePassword.Response>("/accounts/profile/update-password/", data);

export const getCurrentMemberships = async (data: API.Profile.Memberships.Request) =>
  await makeRequest.get<API.Profile.Memberships.Response>("/accounts/profile/memberships/", {
    params: { ...data, statuses: ["active", "trial"] },
  });

export const getCurrentActiveServices = async (data: API.Profile.Memberships.Request = {}) =>
  await makeRequest.get<API.Profile.Memberships.Response>("/accounts/profile/memberships/", {
    params: { ...data, statuses: ["active"], package__type: "service" },
  });

export const getCurrentMembership = async () =>
  await makeRequest.get<API.Profile.CurrentMembership.Response>("/accounts/profile/memberships/current/");

export const cancelCurrentMembership = async (id: API.Profile.Memberships.MembershipData["id"]) =>
  await makeRequest.post<API.Profile.CancelMembership.Response>(`/accounts/profile/memberships/${id}/cancel/`);

export const renewMembership = async (id: API.Profile.Memberships.MembershipData["id"]) =>
  await makeRequest.post<API.Profile.RenewMembership.Response>(`/accounts/profile/memberships/${id}/reactivate/`);

export const getProfileBillingMethodList = async () =>
  await makeRequest.get<API.Profile.BillingList.Response>("/accounts/profile/billing-methods/");

export const setProfileBillingMethodPrimary = async (id: API.Profile.BillingList.BillingData["id"]) =>
  await makeRequest.post<API.Profile.SetPaymentMethodPrimary.Response>(`/accounts/profile/billing-methods/${id}/set-primary/`);

export const deleteProfileBillingMethod = async (id: API.Profile.BillingList.BillingData["id"]) =>
  await makeRequest.delete<API.Profile.DeletePaymentMethod.Response>(`/accounts/profile/billing-methods/${id}/`);

export const addStripePaymentMethod = async (orderId?: API.Packages.OrderData["id"]) => {
  const data: API.Packages.PurchaseOrder.Request = {
    payment_service: "stripe",
  };
  if (orderId) {
    data.order_id = orderId;
  }
  return await makeRequest.post<API.Profile.AddPaymentMethod.Response>("/accounts/profile/billing-methods/", data);
};

export const getProfileInvoiceList = async (params: API.Profile.InvoiceList.Request) =>
  await makeRequest.get<API.Profile.InvoiceList.Response>("/accounts/profile/invoices/", { params });

export const createMarketRequest = async (data: API.Profile.RequestMarket.Request) =>
  await makeRequest.post<API.Profile.RequestMarket.Response>(`/accounts/profile/request-market/`, data);

export const getProfileCredits = async (params: API.Profile.Credits.Request) =>
  await makeRequest.get<API.Profile.Credits.Response>("/accounts/profile/credits/");

export const updateInvestorProfile = async (params: Partial<API.Profile.UpdateInvestorProfile.Request> | FormData) =>
  await makeRequest.patch<API.Profile.UpdateInvestorProfile.Response>("/accounts/investor-profile/", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateProfilePhoneNumber = async (params: Partial<API.Profile.UpdatePhoneNumber.Request>) =>
  await makeRequest.patch<API.Profile.UpdatePhoneNumber.Response>("/accounts/profile/update-phone-number/", params);

export const getProfileMarketCampaignList = async () =>
  await makeRequest.get<API.Profile.MarketCampaign.Response>("/accounts/profile/market-campaigns/");

export const postProfileMarketCampaignAddProperty = async (id: string | number, data: API.Profile.AddPropertyToCampaign.Request) =>
  await makeRequest.post<API.Profile.AddPropertyToCampaign.Response>(`/accounts/profile/market-campaigns/${id}/add-property/`, data);

export const updateProfileIcon = async (formData: API.Profile.UpdateIcon.Request) =>
  await makeRequest.post<API.Profile.UpdateIcon.Response>("/accounts/profile/update-icon/", formData);

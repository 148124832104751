/** @jsxImportSource @emotion/react */
import React from "react";
import { MembershipStatus } from "src/enums/membership";
import Countdown, { CountdownRendererFn } from "react-countdown";
import moment from "moment";
import Text from "src/components/ui/text";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCurrentMembershipRequest } from "src/hooks/api/profile";
import { useProfileStoreWithRefetch } from "src/hooks/profile";
import Icon from "src/components/ui/icons";
import styles, { IStyles } from "src/components/trial-countdown/TrialCountdown.styles";
import { useThemedCss } from "src/hooks/styles";
import { useTutorialStore } from "src/store/tutorial";

interface TrialCountdownProps {
  className?: string;
}

const TrialCountdown: React.FC<TrialCountdownProps> = ({ className }) => {
  const { t } = useTranslation();
  const { activeMembership } = useProfileStoreWithRefetch();
  const themedCss = useThemedCss<IStyles>(styles);
  const { data, isSuccess, isFetched, refetch, error } = useCurrentMembershipRequest(false);

  const { setReadyComponents, setIsAllDataReady } = useTutorialStore();

  const [hasAttemptedRefetch, setHasAttemptedRefetch] = React.useState(false);

  React.useEffect(() => {
    setReadyComponents(["#membership-trial-countdown"]);
  }, []);

  React.useEffect(() => {
    if (activeMembership) {
      refetch().finally();
    }
  }, [activeMembership, refetch]);

  React.useEffect(() => {
    if (isSuccess && data) {
      setIsAllDataReady(true);
    }
  }, [isSuccess, data]);

  React.useEffect(() => {
    if (!isSuccess && !hasAttemptedRefetch) {
      refetch()
        .then(() => setHasAttemptedRefetch(true))
        .catch(() => setHasAttemptedRefetch(true));
    }
  }, [isSuccess, hasAttemptedRefetch, refetch]);

  const currentMembership = React.useMemo(() => {
    if (data?.data) {
      return data.data;
    }
    setHasAttemptedRefetch(false);
    return null;
  }, [data]);

  if (!data) {
    return null;
  }

  if (currentMembership && currentMembership.status !== MembershipStatus.TRIAL) {
    return (
      <Link to="/settings/membership/" className={className} css={themedCss.badge} id="membership-trial-countdown">
        {currentMembership.package.name}
        <Icon name="arrow-external" variant="primary" size={14} className="ms-3" />
      </Link>
    );
  }

  if (isSuccess && !currentMembership) {
    return (
      <Link to="/membership/select/" className={className} css={themedCss.badge} id="membership-trial-countdown">
        {t("components.trial_countdown.button_text", "Membership")}
        <Icon name="arrow-external" variant="primary" size={14} className="ms-3" />
      </Link>
    );
  }

  const renderer: CountdownRendererFn = ({ completed, days: rawDays, hours: rawHours, formatted: { hours, minutes, seconds } }) => {
    if (completed) {
      return null;
    }
    let color: "dark" | "danger" | "warning" = "dark";

    if (rawDays === 0 && rawHours < 12) {
      color = "danger";
    } else if (rawDays === 0 && rawHours < 24) {
      color = "warning";
    }

    return <Text color={color}>{`${hours}:${minutes}:${seconds}`}</Text>;
  };

  return (
    <Link to="/membership/select/" className={className} css={themedCss.badge} id="membership-trial-countdown">
      <Countdown date={moment(currentMembership!.ended_at).toDate()} daysInHours renderer={renderer} />
      <Icon name="arrow-external" variant="primary" size={14} className="ms-3" />
    </Link>
  );
};

export default TrialCountdown;

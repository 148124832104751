// eslint-disable-next-line no-shadow
export enum MembershipPermission {
  ACCESS_VERIFIED_PROPERTY_FEED = "access_verified_property_feed",
  ACCESS_PROPERTY_OUTREACH = "access_property_outreach",
  ACCESS_PROPERTY_FEED = "access_property_feed",
  ACCESS_VERIFIED_APARTMENTS_FEED = "access_verified_apartments_feed",
  ACCESS_ARBITRAGE_CALCULATOR = "access_arbitrage_calculator",
  ACCESS_ASSUMPTION_CUSTOMIZATION = "access_assumption_customization",
  ACCESS_PROPERTY_FILTER = "access_property_filter",
  ACCESS_PROPERTY_ADMIN_FILTER = "access_property_admin_filter",
  ACCESS_DEAL_ALERTS = "access_deal_alerts",
  ACCESS_SHARE_CODE = "access_share_code",
  ACCESS_BUSINESS_PORTFOLIO = "access_business_portfolio",
  ACCESS_STR_DATABASE = "access_str_database",
  ACCESS_STR_DATABASE_STATE = "access_str_database_state",
  ACCESS_STR_MONITOR = "access_str_monitor",
  ACCESS_COURSE = "access_course",
  ACCESS_COACH = "access_coach",
  ACCESS_MESSAGE = "access_message",
  ACCESS_LOCAL_PULSE = "access_local_pulse",
  ACCESS_SAVED_SEARCH = "access_saved_search",
  ACCESS_PROPERTY_COMP_SET = "access_property_comp_set",
  ACCESS_PROPERTY_ADDRESS = "access_property_address",
  ACCESS_VENDORS_PAGE = "access_vendors_page",
  ACCESS_PARTNER_PAGE = "access_partner_page",
  ACCESS_REFERRAL_PROGRAM = "access_referral_program",
  ACCESS_HELP = "access_help",
  ACCESS_FULL_ADDRESS = "access_full_address",
  ACCESS_PROPERTY_CSV_EXPORT = "access_property_csv_export",
  ACCESS_VENDOR_VERIFICATION = "access_vendor_verification",
  ACCESS_PARTNER_VERIFICATION = "access_partner_verification",
  ACCESS_AGENT_DASHBOARD = "access_agent_dashboard",
  ACCESS_AGENT_MARKET_CAMPAIGNS = "access_agent_market_campaigns",
  ACCESS_AGENT_PROPERTY_SUBMIT = "access_agent_property_submit",
  ACCESS_AGENT_PROPERTY_TABLE = "access_agent_property_table",
  ADD_MARKET_CAMPAIGN = "add_marketcampaign",
  ACCESS_MARKET_CAMPAIGN_LIST = "access_market_campaign_list",
  ACCESS_MARKET_CAMPAIGN_AGENT_ASSIGN = "access_market_campaign_agent_assign",
  ACCESS_MARKET_CAMPAIGN_REJECT = "access_market_campaign_reject",
  ACCESS_MARKET_CAMPAIGN_APPROVE_PROPERTIES = "access_market_campaign_approve_properties",
  ACCESS_PROPERTY_ARBITRAGE_LIST = "access_property_arbitrage_list",
  ACCESS_PROPERTY_ARBITRAGE_VERIFIED_LIST = "access_property_arbitrage_verified_list",
  ACCESS_PROPERTY_CO_HOST_LIST = "access_property_co_host_list",
  ACCESS_PROPERTY_CO_HOST_VERIFIED_LIST = "access_property_co_host_verified_list",
  ACCESS_AGENT_MARKET_CAMPAIGN_REMOVE = "access_agent_market_campaign_remove",
  ACCESS_PROPERTY_APARTMENT_LIST = "access_property_apartment_list",
  ACCESS_PROPERTY_APARTMENT_VERIFIED_LIST = "access_property_apartment_verified_list",
  ACCESS_AGENT_MARKET_CAMPAIGN_EXECUTION = "access_agent_market_campaign_execution",
  ACCESS_AGENT_STR_DATABASE_VERIFICATION = "access_agent_str_database_verification",
  ACCESS_AGENT_STR_DATABASE_MODIFICATION = "access_agent_str_database_modification",
  ACCESS_AGENT_STATISTICS = "access_agent_statistics",
  ACCESS_LOCKED_COURSE = "locked_course_access",
}

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRegistrationRequest } from "src/hooks/api/registration";
import { useForm } from "react-hook-form";
import Text from "src/components/ui/text";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";
import { Mail, PhoneIcon, Eye, EyeOff, Lock } from "lucide-react";

// Shadcn UI components
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/zForm";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { useAuthStore } from "src/store/account";

type FormValue = API.Registration.CreateAccount.Request;

const initialFormValue: Omit<FormValue, "referral_code"> = {
  email: "",
  password: "",
  phone_number: "",
  first_name: "",
  last_name: "",
  is_privacy_policy_accepted: true,
  is_subscribe_newsletter: true,
  is_sms_opt_in_accepted: true,
};

export const CheckboxPrivacyText: React.FC = () => (
  <Trans
    i18nKey="By creating an account, you agree to our <terms>Terms of use</terms>."
    components={{
      terms: <Text.ExternalLink inherit blank href="https://airvana.co/terms-of-use/" />,
    }}
  />
);

export const CheckboxSMSOptInText: React.FC = () => (
  <Trans
    i18nKey="components.registration.CheckboxSMSOptInText"
    components={{
      terms: <Text.ExternalLink inherit blank href="https://airvana.co/terms-of-use/" />,
    }}
  >
    <span>I agree to receive automated messages (up to 5/month). Msg&data rates may apply.</span>
    {" <terms>Terms & Privacy Policy</terms>. "}
    Reply STOP to cancel.
  </Trans>
);

const RegistrationForm: React.FC<{ isFreeTrial?: boolean }> = ({ isFreeTrial = false }) => {
  const { t } = useTranslation();
  const { setAccessToken, setRefreshToken, setIsAuthenticated } = useAuthStore();
  const navigate = useNavigate();

  const requiredMessage = i18next.t("field_required_error", "This field is required.").toString();
  const maxValueTextError = i18next.t("max_value_text_len_error", "The value could not exceed [max] characters.").toString();
  const fieldRequiredError = i18next.t("field_required_error", "This field is required.").toString();

  const schema = yup
    .object({
      email: yup.string().email(i18next.t("The given email address is not valid.").toString()).required(requiredMessage),
      password: yup.string().required(requiredMessage),
      phone_number: yup
        .string()
        .max(15, maxValueTextError.replace("[max]", "15"))
        .test("test-phone-number", "Phone number can contain only the following characters: -, (, ), digits and spaces.", (value) => {
          if (!value) {
            return true;
          }
          return value.match(/^[+\d ()-]+$/g) !== null;
        })
        .required(requiredMessage),
      /*       first_name: yup.string().max(50, maxValueTextError.replace("[max]", "50")).required(fieldRequiredError),
      last_name: yup.string().max(50, maxValueTextError.replace("[max]", "50")).required(fieldRequiredError),
      city_text: yup.string().required(fieldRequiredError),
      state_text: yup.string().required(fieldRequiredError), */
      is_subscribe_newsletter: yup.boolean(),
      is_sms_opt_in_accepted: yup
        .boolean()
        .oneOf([true], i18next.t("You must accept the SMS opt-in to continue.").toString())
        .required(requiredMessage),
      is_privacy_policy_accepted: yup
        .boolean()
        .oneOf([true], i18next.t("You must accept the terms of use.").toString())
        .required(requiredMessage),
    })
    .required();

  const form = useForm<FormValue>({
    defaultValues: initialFormValue,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { data, isError, error, isLoading, mutate, isSuccess } = useRegistrationRequest();

  const onSubmit = (formData: FormValue) => {
    if (!form.formState.isValid) {
      return;
    }
    mutate({
      ...formData,
      referral_code: localStorage.getItem("referral") ?? null,
    });
  };

  React.useEffect(() => {
    if (isError && error.response?.data) {
      const errorData = error.response.data as Record<string, string>;
      Object.keys(initialFormValue).forEach((key) => {
        if (errorData[key]) {
          form.setError(key as keyof FormValue, {
            type: "server",
            message: errorData[key],
          });
        }
      });
    }
  }, [isError, error]);

  React.useEffect(() => {
    if (isSuccess && data?.data) {
      setAccessToken(data.data.tokens.access);
      setRefreshToken(data.data.tokens.refresh);
      setIsAuthenticated(true);
      setTimeout(() => {
        navigate("/onboarding");
      }, 1000);
    }
  }, [isSuccess, data]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        {error?.response?.data?.detail && (
          <div className="bg-destructive/15 text-destructive p-3 rounded-md">{error.response.data.detail}</div>
        )}
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("First name")}</FormLabel>
                <FormControl>
                  <Input placeholder={t("First name") || "First name"} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("Last name")}</FormLabel>
                <FormControl>
                  <Input placeholder={t("Last name") || "Last name"} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/*           <FormField
            control={form.control}
            name="city_text"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("City")}</FormLabel>
                <FormControl>
                  <Input placeholder={t("City") || "City"} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="state_text"
            render={({ field }) => {
              const { data: stateData } = useStateListRequest();

              const statesList = React.useMemo(() => {
                if (!stateData) {
                  return [];
                }
                setTimeout(() => {
                  if (initialFormValue.state_text) {
                    form.setValue<"state_text">("state_text", initialFormValue.state_text);
                  }
                });
                return stateData.data;
              }, [stateData]);

              return (
                <FormItem>
                  <FormLabel>{t("State")}</FormLabel>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder={t("Select a state")} />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {statesList.map((state) => (
                        <SelectItem value={state.code} key={state.code}>
                          {state.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              );
            }}
          /> */}
        </div>

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("registration_form.email_label", "Email")}</FormLabel>
              <FormControl>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground h-4 w-4" />
                  <Input placeholder="example@site.com" className="pl-10 pr-3" type="email" autoComplete="email" {...field} />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="phone_number"
          render={({ field }) => {
            const formatPhoneNumber = (value: string) => {
              // Remove all non-digit characters
              let cleaned = value.replace(/\D/g, "");

              // If the value doesn't start with 1, add it (US country code)
              if (cleaned.length > 0 && !cleaned.startsWith("1")) {
                cleaned = "1" + cleaned;
              }

              // Format for E.164 format required by Django: +11234567890
              if (cleaned.length > 0) {
                return "+" + cleaned;
              }

              return value;
            };

            // For display purposes only
            const formatDisplayValue = (value: string) => {
              if (!value) return "";

              // Strip to digits only
              const digits = value.replace(/\D/g, "");

              // Don't try to format if we don't have enough digits
              if (digits.length < 4) return value;

              // Format: +1 (XXX) XXX-XXXX
              const countryCode = digits.slice(0, 1);
              const areaCode = digits.slice(1, 4);
              const firstPart = digits.slice(4, 7);
              const lastPart = digits.slice(7, 11);

              let formatted = "+";
              formatted += countryCode;
              if (areaCode) formatted += " (" + areaCode + ")";
              if (firstPart) formatted += " " + firstPart;
              if (lastPart) formatted += "-" + lastPart;

              return formatted;
            };

            return (
              <FormItem>
                <FormLabel>{t("registration_form.phone_number_label", "Phone number")}</FormLabel>
                <FormControl>
                  <div className="relative">
                    <PhoneIcon className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground h-4 w-4" />
                    <Input
                      placeholder="(123) 456-7890"
                      className="pl-10 pr-3"
                      value={formatDisplayValue(field.value)}
                      onChange={(e) => {
                        // Store the raw E.164 format internally
                        const rawValue = formatPhoneNumber(e.target.value);
                        field.onChange(rawValue);
                      }}
                      onBlur={(e) => {
                        // Make sure the stored value is properly formatted when field loses focus
                        const formattedValue = formatPhoneNumber(e.target.value);
                        field.onChange(formattedValue);
                        field.onBlur();
                      }}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => {
            const [showPassword, setShowPassword] = React.useState(false);
            return (
              <FormItem>
                <FormLabel>{t("registration_form.password_label", "Password")}</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground h-4 w-4" />
                    <Input
                      type={showPassword ? "text" : "password"}
                      className={`pl-10 pr-3 ${showPassword ? "" : "tracking-[0.25em]"} [&::placeholder]:tracking-[0.25em] [&::-ms-reveal]:hidden [&::-ms-clear]:hidden [&::-webkit-credentials-auto-fill-button]:hidden [&::-webkit-contacts-auto-fill-button]:hidden`}
                      autoComplete="new-password"
                      autoCapitalize="none"
                      autoCorrect="off"
                      placeholder="••••••••"
                      spellCheck="false"
                      data-lpignore="true"
                      {...field}
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="absolute right-0 top-0 h-full px-3 py-2"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4 text-muted-foreground" />
                      ) : (
                        <Eye className="h-4 w-4 text-muted-foreground" />
                      )}
                      <span className="sr-only">{showPassword ? "Hide password" : "Show password"}</span>
                    </Button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <Button type="submit" className="w-full" disabled={isLoading || !form.formState.isValid}>
          {isLoading ? t("Loading...") : t("registration_form.sign_up_button_text", "Sign Up")}
        </Button>
        <div className="text-center text-xs text-muted-foreground">
          <Trans
            i18nKey="By clicking Sign Up, you agree to our <terms>Terms of Use</terms>, <privacy>Privacy Policy</privacy> and receive offers and updates from Airvana."
            components={{
              terms: <Text.ExternalLink inherit blank href="https://airvana.co/terms-of-use/" />,
              privacy: <Text.ExternalLink inherit blank href="https://airvana.co/privacy-policy/" />,
            }}
          />
        </div>
      </form>
    </Form>
  );
};

export default RegistrationForm;

import React from "react";
import { useAuthStore } from "src/store/account";
import { useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { useLocalStorage } from "./useLocalStorage";

export const useLogout = (): (() => void) => {
  const { unAuthenticate } = useAuthStore();
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useLocalStorage("onboardingStep", 1);

  return React.useCallback(() => {
    unAuthenticate();
    queryClient.cancelQueries().finally();
    queryClient.invalidateQueries().finally();
    queryClient.getQueryCache().clear();
    setCurrentStep(1);
    Sentry.setUser(null);
  }, [unAuthenticate, queryClient, setCurrentStep]);
};

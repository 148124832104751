import React, { useCallback, useMemo, useRef, useState } from "react";
import { Button } from "src/components/ui/button";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Input } from "src/components/ui/input";
import { Loader2, RefreshCw, Plus } from "lucide-react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { Checkbox } from "src/components/ui/checkbox";
import { getMonitorsList } from "src/api/admin/local-pulse";
import { MonitorConfig } from "src/pages/admin/local-pulse/local-monitor/CustomDialogContent";

interface ExistingMonitorFormProps {
  name: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onSelectMonitors: (monitors: MonitorConfig[]) => void;
  currentMonitors: MonitorConfig[];
  singleSelect?: boolean;
}

export default function ExistingMonitorForm({
  name = "Existing Monitor",
  isOpen,
  setIsOpen,
  onSelectMonitors,
  currentMonitors,
  singleSelect = false,
}: ExistingMonitorFormProps) {
  const [search, setSearch] = useState("");
  const [selectedMonitors, setSelectedMonitors] = useState<MonitorConfig[]>(currentMonitors);
  const parentRef = useRef<HTMLDivElement>(null);
  // Fetch monitors with pagination
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ["monitors", search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getMonitorsList({
        page: pageParam,
        limit: 50,
        search,
      });
      return response;
    },
    getNextPageParam: (lastPage, pages) => (lastPage.data.hasMore ? pages.length + 1 : undefined),
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
  });
  // Flatten all pages into a single array
  const allMonitors: MonitorConfig[] = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.data.results.map((monitor) => ({
          ...monitor,
          showDetails: false,
        })),
      ) ?? [],
    [data],
  );

  // Load more when scrolling near bottom
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight * 1.5 && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  return (
    <>
      <Button type="button" variant="outline" size="sm" className="text-xs px-2 w-full h-10" onClick={() => setIsOpen(true)}>
        <Plus className="h-3 w-3 mr-2" />
        {name}
      </Button>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="h-[100vh] max-h-[100vh] md:h-auto md:max-h-[90vh] z-[1025] overflow-y-auto" overlayClassName="z-[1024]">
          <DialogHeader>
            <DialogTitle>Select Existing Monitors</DialogTitle>
          </DialogHeader>
          <DialogDescription />
          <div className="flex flex-col h-full p-3">
            {/* Search Input */}
            <div className="mb-4">
              <Input placeholder="Search monitors..." value={search} onChange={(e) => setSearch(e.target.value)} className="w-full" />
            </div>
            {/* Virtualized List */}
            <div ref={parentRef} onScroll={handleScroll} className="flex-1 overflow-auto min-h-[400px] max-h-[50vh]">
              {allMonitors.map((monitor) => (
                <div
                  key={monitor.id}
                  className="flex items-center space-x-2 p-2 hover:bg-slate-200 rounded cursor-pointer"
                  onClick={() => {
                    if (singleSelect) {
                      if (selectedMonitors.some((m) => m.id === monitor.id)) {
                        setSelectedMonitors([]);
                      } else {
                        setSelectedMonitors([monitor]);
                      }
                      return;
                    }
                    if (selectedMonitors.some((m) => m.id === monitor.id)) {
                      setSelectedMonitors(selectedMonitors.filter((m) => m.id !== monitor.id));
                    } else {
                      setSelectedMonitors([...selectedMonitors, monitor]);
                    }
                  }}
                >
                  <Checkbox checked={selectedMonitors.some((m) => m.id === monitor.id)} />
                  <span className="text-sm">{monitor.name || monitor.query}</span>
                </div>
              ))}
            </div>
            {/* Loading indicator */}
            {(isLoading || isFetchingNextPage) && (
              <div className="flex justify-center py-2">
                <Loader2 className="h-6 w-6 animate-spin" />
              </div>
            )}
          </div>
          {/* Actions */}
          <DialogFooter className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={() => {
                setSearch("");
                refetch();
              }}
              className="text-xs px-2"
            >
              <RefreshCw className="h-4 w-4" />
            </Button>
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSelectMonitors(selectedMonitors);
                setSelectedMonitors([]);
                setIsOpen(false);
              }}
              disabled={selectedMonitors.length === 0}
            >
              {singleSelect ? "Add Selected" : `Add Selected (${selectedMonitors.length})`}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}

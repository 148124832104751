// eslint-disable-next-line no-shadow
export enum PropertyType {
  SINGLE_FAMILY = "single_family",
  TOWNHOUSE = "townhouse",
  APARTMENT = "apartment",
  OTHER = "other",
}

// eslint-disable-next-line no-shadow
export enum PropertyListDateFilter {
  LAST_24_HOURS = "last_24_hours",
  LAST_7_DAYS = "last_7_days",
  LAST_30_DAYS = "last_30_days",
}

// eslint-disable-next-line no-shadow
export enum PropertyUnderwritingEstimateType {
  CONSERVATIVE = "conservative",
  REALISTIC = "realistic",
  OPTIMISTIC = "optimistic",
}

// eslint-disable-next-line no-shadow
export enum PropertyStatus {
  NEW = "new",
  VERIFIED = "verified",
  LISTED = "listed",
  LEASED = "leased",
  NEEDS_VERIFICATION = "needs_verification",
  HAS_ERROR = "has_error",
  OUT_OF_BOUNDS = "out_of_bounds",
  NOT_EXTERNALLY_LISTED = "not_listed",
  DELETED_BY_LANDLORD = "deleted_by_landlord",
  INACTIVE = "inactive",
}

// eslint-disable-next-line no-shadow
export enum PropertyFurnishingEnum {
  NOT_SET = "not_set",
  FURNISHED = "furnished",
  NOT_FURNISHED = "not_furnished",
  PARTIALLY_FURNISHED = "partially_furnished",
}

// eslint-disable-next-line no-shadow
export enum PropertyKeywordCategory {
  ARBITRAGE = "arbitrage",
  CO_HOST = "co_host",
  APARTMENT = "apartment",
}

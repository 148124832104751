import React, { useMemo } from "react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { Button } from "src/components/ui/button";
import { runLocalMonitorTest, runMonitorTest } from "src/api/admin/local-pulse";
import { ChevronDown, ChevronUp, Loader2 } from "lucide-react";
import { v4 as uuidv4 } from "uuid";

// eslint-disable-next-line no-shadow
export enum TestState {
  CONFIRMING = "CONFIRMING",
  LOADING = "LOADING",
  RUNNING = "RUNNING",
  ERROR = "ERROR",
}

interface TestMonitorProps {
  isTesting: boolean;
  setIsTesting: (isTesting: boolean) => void;
  testAreaId?: number;
  testMonitor?: AdminAPI.LocalPulse.MonitorConfig;
}

interface TestResultProps {
  result: any;
  index: number;
}

type TestMonitorResultType = AdminAPI.LocalPulse.RunMonitorTest.Response | AdminAPI.LocalPulse.RunLocalMonitorTest.Response;

export const TestResult = ({ result, index }: TestResultProps) => {
  const [showJson, setShowJson] = React.useState(false);
  const { title, link, snippet, displayLink } = result;

  return (
    <div className="space-y-2">
      <div className="space-y-2">
        <h3 className="font-medium text-sm">{title}</h3>
        <div className="text-xs space-y-1">
          <p>{snippet}</p>
          <a href={link} className="text-primary hover:underline" target="_blank" rel="noopener noreferrer">
            {displayLink}
          </a>
        </div>
      </div>

      <div className="space-y-2">
        <Button variant="ghost" size="sm" className="h-8 text-xs w-full justify-between" onClick={() => setShowJson(!showJson)}>
          {showJson ? "Hide" : "Show"} JSON
          {showJson ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
        </Button>

        {showJson && (
          <pre
            className="text-xs bg-muted/50 p-2 rounded-md overflow-x-auto w-full break-words break-all"
            style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
          >
            {JSON.stringify(result, null, 2)}
          </pre>
        )}
      </div>
    </div>
  );
};

const TestMonitor = ({ setIsTesting, testAreaId, isTesting, testMonitor }: TestMonitorProps) => {
  const [testState, setTestState] = React.useState<TestState>(TestState.CONFIRMING);
  const [testMonitorResult, setTestMonitorResult] = React.useState<TestMonitorResultType>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const formatErrorDetails = (details: any): string => {
    if (typeof details === "string") return details;

    // Handle nested object structure
    return Object.entries(details)
      .map(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          // Handle nested arrays of errors
          return `${key}: ${Object.values(value as Record<string, string[]>)
            .flat()
            .join(", ")}`;
        }
        return value;
      })
      .filter(Boolean)
      .join(", ");
  };

  const handleRunTest = async ({ areaId, monitor }: { areaId?: number; monitor?: AdminAPI.LocalPulse.MonitorConfig }) => {
    try {
      setTestState(TestState.LOADING);
      let response;

      if (monitor) {
        response = await runMonitorTest({ monitor });
      } else if (areaId) {
        response = await runLocalMonitorTest({ area_id: areaId });
      } else {
        throw new Error("Either areaId or monitor must be provided");
      }

      setTestMonitorResult(response.data);
      setTestState(TestState.RUNNING);
    } catch (error: any) {
      if (error.response.data.details) {
        setErrorMessage(`${error.response.data.error}: ${formatErrorDetails(error.response.data.details)}`);
      } else if (error.response.data.message) {
        setErrorMessage(error.response.data.message);
      }
      setTestState(TestState.ERROR);
    }
  };

  const testMonitorContent = useMemo(() => {
    switch (testState) {
      case TestState.CONFIRMING:
        return (
          <div className="p-3 text-center">
            <p>Are you sure you want to run a test with this area?</p>
            <DialogFooter>
              <Button variant="outline" className="text-xs px-2 w-full md:w-auto" onClick={() => setIsTesting(false)}>
                Cancel
              </Button>
              <Button
                variant="default"
                onClick={() => {
                  if (testAreaId) {
                    handleRunTest({ areaId: testAreaId });
                  } else if (testMonitor) {
                    handleRunTest({ monitor: testMonitor });
                  }
                }}
                className="text-xs px-2 w-full md:w-auto"
                disabled={!testAreaId && !testMonitor}
              >
                Run test
              </Button>
            </DialogFooter>
          </div>
        );

      case TestState.LOADING:
        return (
          <div className="flex flex-col items-center justify-center py-8 space-y-4 px-3">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
            <p className="text-muted-foreground">Initializing test...</p>
          </div>
        );

      case TestState.RUNNING:
        return (
          <div className="space-y-4 p-3">
            <div className="flex items-center space-x-2">
              <div className="h-2 w-2 rounded-full bg-green-500 animate-pulse" />
              <p className="text-sm font-medium mb-0">Test in progress</p>
            </div>

            <div className="max-h-[400px] overflow-y-auto rounded-md border customScrollbar2">
              {testMonitorResult.length > 0 ? (
                <div className="divide-y">
                  {testMonitorResult.map((result, index) => (
                    <div key={uuidv4()} className="p-4 hover:bg-muted/50 transition-colors">
                      <div className="text-xs text-muted-foreground mb-2">Result {index + 1}</div>
                      <TestResult result={result} index={index} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="p-4 text-center text-muted-foreground">No results found</div>
              )}
            </div>

            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setTestState(TestState.CONFIRMING);
                  setIsTesting(false);
                  setTestMonitorResult([]);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </div>
        );

      case TestState.ERROR:
        return (
          <div className="space-y-4 p-3">
            <div className="flex items-center space-x-2">
              <div className="h-2 w-2 rounded-full bg-red-500 animate-pulse" />
              <p className="text-sm font-medium mb-0">Error running test</p>
            </div>

            <div className="max-h-[400px] overflow-y-auto rounded-md border customScrollbar2">
              <div className="p-4 text-center text-muted-foreground">{errorMessage}</div>
            </div>

            <DialogFooter>
              <Button
                variant="outline"
                onClick={() => {
                  setTestState(TestState.CONFIRMING);
                  setIsTesting(false);
                  setTestMonitorResult([]);
                }}
              >
                Close
              </Button>
            </DialogFooter>
          </div>
        );

      default:
        return null;
    }
  }, [testState, testAreaId, testMonitor, setIsTesting, testMonitorResult]);

  return (
    <Dialog
      open={isTesting}
      onOpenChange={(open) => {
        if (!open) {
          setIsTesting(false);
          setTestMonitorResult([]);
          setTestState(TestState.CONFIRMING);
        }
      }}
    >
      <DialogContent className="z-[1025] overflow-y-auto" overlayClassName="z-[1024]">
        <DialogTitle>Run Monitor Test</DialogTitle>
        <DialogDescription />
        {testMonitorContent}
      </DialogContent>
    </Dialog>
  );
};

export default TestMonitor;

import { UseQueryResult } from "@tanstack/react-query/src/types";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLocalMonitors } from "src/api/admin/local-pulse";

type IUseAdminGetLocalMonitorsRequest = (
  params?: AdminAPI.LocalPulse.GetLocalMonitors.Request,
) => UseQueryResult<
  AxiosResponse<AdminAPI.LocalPulse.GetLocalMonitors.Response>,
  AxiosError<AdminAPI.LocalPulse.GetLocalMonitors.ErrorResponse>
>;

//get all the monitors for a given area id
export const useAdminGetLocalMonitorsRequest: IUseAdminGetLocalMonitorsRequest = (params) =>
  useQuery(["useGetLocalMonitorsRequest", "useAdminGetLocalMonitorsRequest", params], async () => await getLocalMonitors(params), {
    enabled: !!params?.area_ids && params.area_ids.length > 0,
  });

import React from "react";
import { ChipMenu } from "src/components/ui/menu";
import { Outlet } from "react-router-dom";

const LocalPulsePage = () => {
  const items = React.useMemo(() => {
    const allowedItems = [];

    allowedItems.push({ to: "/admin/local-pulse/", title: "Dashboard" });
    allowedItems.push({ to: "/admin/local-pulse/local-monitor", title: "Monitor Connections" });
    allowedItems.push({ to: "/admin/local-pulse/monitor-config", title: "Monitors" });

    return allowedItems;
  }, []);

  return (
    <section>
      <ChipMenu level={3} style={{ marginBottom: 24 }} items={items} />
      <Outlet />
    </section>
  );
};

export default LocalPulsePage;

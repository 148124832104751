import React from "react";
import type { RouteObject } from "react-router-dom";
import AdminRequiredWrapper from "src/pages/agent/AgentRequiredWrapper";
import LocalPulsePage from "src/pages/admin/local-pulse/localpulsePage";
import LocalMonitorPage from "src/pages/admin/local-pulse/local-monitor/localmonitorPage";
import MonitorConfigPage from "src/pages/admin/local-pulse/monitor-config/MonitorConfigPage";
import Dashboard from "src/pages/admin/local-pulse/dashboard";

const localPulseRouter: RouteObject[] = [
  {
    path: "local-pulse",
    element: (
      <AdminRequiredWrapper>
        <LocalPulsePage />
      </AdminRequiredWrapper>
    ),
    children: [
      {
        index: true,
        element: (
          <AdminRequiredWrapper>
            <Dashboard />
          </AdminRequiredWrapper>
        ),
      },
      {
        path: "local-monitor",
        element: (
          <AdminRequiredWrapper>
            <LocalMonitorPage />
          </AdminRequiredWrapper>
        ),
        children: [
          {
            path: ":page/",
            element: (
              <AdminRequiredWrapper>
                <LocalMonitorPage />
              </AdminRequiredWrapper>
            ),
          },
        ],
      },
      {
        path: "monitor-config",
        element: (
          <AdminRequiredWrapper >
            <MonitorConfigPage />
          </AdminRequiredWrapper>
        ),
      },
    ],
  },
];

export default localPulseRouter;

import React, { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useAuthStore } from "src/store/account";
import { SocketStatus, useSocket } from "src/hooks/useSocket";
import config from "src/config";
import { useToastStore } from "src/store/toast";
import { MessageRequest } from "src/components/message/provider";

interface StatusContextType {
  onlineUsers: API.Messages.User[];
  setOnlineUsers: (users: API.Messages.User[]) => void;
  statusChat: SocketStatus;
  sendMessageChat: (message: string) => void;
  onMessageChat: (callback: (message: string) => void) => void;
  onlineStatus: boolean;
  messageRequests: MessageRequest;
  setMessageRequests: (requests: MessageRequest) => void;
  activeChat: API.Messages.Chat | null;
  setActiveChat: (chat: API.Messages.Chat | null) => void;
  chats: API.Messages.ChatList[];
  setChats: (chats: API.Messages.ChatList[]) => void;
}

const StatusContext = createContext<StatusContextType | undefined>(undefined);

export const StatusProvider: React.FC<{ children: React.ReactNode; socketUrl: string }> = ({ children, socketUrl }) => {
  const [onlineUsers, setOnlineUsers] = useState<API.Messages.User[]>([]);

  const { status: statusChat, sendMessage: sendMessageChat, onMessage: onMessageChat, error: errorChat } = useSocket(socketUrl);
  const [onlineStatus, setOnlineStatus] = useState<boolean>(false);
  const [messageRequests, setMessageRequests] = useState<MessageRequest>({
    total: 0,
    results: [],
  });
  const { addToastDanger } = useToastStore();
  const [activeChat, setActiveChat] = useState<API.Messages.Chat | null>(null);
  const [chats, setChats] = useState<API.Messages.ChatList[]>([]);

  const handleWebsocketMessage = React.useCallback(
    (message: string) => {
      try {
        const data = JSON.parse(message);
        // Handle status messages
        if (data.type === "online_status") {
          setOnlineUsers(data.online_users);
        }
        // Handle partner request messages
        if (data.type === "partner_request") {
          setMessageRequests((prev) => ({
            ...prev,
            total: prev.total + 1,
            results: [data.request, ...prev.results],
          }));
        }
        // Handle error messages
        if (data.type === "error") {
          addToastDanger(data.error);
        }
        // Handle chat messages
        if (data.type === "chat") {
          if (data.chat.conversation_name === activeChat?.conversation_name) {
            setActiveChat((prev) => {
              if (!prev) return null;
              return { ...prev, messages: [...prev.messages, data.chat.message] };
            });
          }
          setChats((prev) => {
            if (!prev) return [];
            return prev.map((chat) => {
              if (chat.conversation_name === data.chat.conversation_name) {
                return {
                  ...chat,
                  lastMessage: data.chat.message,
                  readed: data.chat.conversation_name === activeChat?.conversation_name ? chat.readed : chat.readed + 1,
                };
              }
              return chat;
            });
          });
        }
      } catch (error) {
        addToastDanger({
          title: "Error parsing WebSocket message",
        });
      }
    },
    [addToastDanger, activeChat],
  );

  useEffect(() => {
    onMessageChat(handleWebsocketMessage);

    // Cleanup function to remove the message handler
    return () => {
      onMessageChat(() => {});
    };
  }, [onMessageChat, handleWebsocketMessage]);

  useEffect(() => {
    setOnlineStatus(statusChat === "connected");
    if (statusChat !== "connected") {
      setOnlineUsers([]);
    }
  }, [statusChat]);

  const value: StatusContextType = useMemo(
    () => ({
      onlineUsers,
      setOnlineUsers,
      statusChat,
      sendMessageChat,
      onMessageChat,
      onlineStatus,
      messageRequests,
      setMessageRequests,
      activeChat,
      setActiveChat,
      chats,
      setChats,
    }),
    [
      onlineUsers,
      setOnlineUsers,
      statusChat,
      sendMessageChat,
      onMessageChat,
      onlineStatus,
      messageRequests,
      setMessageRequests,
      activeChat,
      setActiveChat,
      chats,
      setChats,
    ],
  );

  return <StatusContext.Provider value={value}>{children}</StatusContext.Provider>;
};

export const useStatus = () => {
  const context = useContext(StatusContext);
  if (!context) {
    throw new Error("useStatus must be used within a StatusProvider");
  }
  return context;
};

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useAuthStore } from "src/store/account";
import { useLoginRequest } from "src/hooks/api/authentication";
import PagePreloader from "src/components/loaders";
import { useBooleanState } from "src/hooks/boolean";
import { useRegistrationRequest } from "src/hooks/api/registration";
import { Trans, useTranslation } from "react-i18next";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Button } from "src/components/ui/button";
import { Link } from "react-router-dom";
import Text from "src/components/ui/text";
import { useProfileHasAnyPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";
import Divider from "src/components/ui/divider";
import BookCall from "src/components/book-call";
import SocialLogin from "src/components/social-login";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18next from "i18next";
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogFooter } from "src/components/ui/dialog";
import { useForm } from "react-hook-form";
import { useBreakpointIsLowerOrEqual } from "src/hooks/responsive";
import LoginForm from "src/components/forms/login/LoginForm";
import RegistrationForm from "src/components/forms/registration/RegistrationForm";

interface IDemoWrapper {
  children: JSX.Element;
  demoComponent?: JSX.Element;
  permission: MembershipPermission | MembershipPermission[];
}

type FormLoginValue = API.Authentication.ObtainToken.Request;

const DemoRegistrationForm = (props: { setIsLogin: (value: boolean) => void }) => {
  const { setIsLogin } = props;
  const { t } = useTranslation();
  const isMobile = useBreakpointIsLowerOrEqual("sm");

  return (
    <DialogContent className="z-[1024] h-[100vh]" overlayClassName="z-[1023]" hideClose={!isMobile}>
      <DialogTitle className="!text-2xl !p-0 text-center">{t("Sign Up")}</DialogTitle>
      <DialogDescription className="sr-only" />
      <div className="flex-1 overflow-y-auto customScrollbar2 py-3 px-6 h-fit">
        <SocialLogin />
        <Divider className="my-2" text={t("common.or", "or") as string} />
        <RegistrationForm />
        <div className="flex items-center justify-start gap-2 mt-3">
          <span className="text-muted-foreground text-sm">{t("Already have an account?")}</span>
          <Button variant="link" className="p-0 h-fit" onClick={() => setIsLogin(true)}>
            {t("Log in now")}
          </Button>
        </div>
      </div>
    </DialogContent>
  );
};

const DemoLoginForm = (props: { setIsLogin: (value: boolean) => void }) => {
  const { t } = useTranslation();
  const { setIsLogin } = props;
  const isMobile = useBreakpointIsLowerOrEqual("sm");

  return (
    <DialogContent className="z-[1024]" overlayClassName="z-[1023]" hideClose={!isMobile}>
      <DialogTitle className="!text-2xl !p-0 text-center">{t("Sign In")}</DialogTitle>
      <div className="flex-1 overflow-y-auto customScrollbar2 p-3 h-fit">
        <SocialLogin />
        <Divider className="my-2" text={t("common.or", "or") as string} />
        <LoginForm />
        <div className="flex items-center justify-start gap-2 mt-3">
          <span className="text-muted-foreground text-sm">{t("Don't have an account?")}</span>
          <Button variant="link" className="p-0 h-fit" onClick={() => setIsLogin(false)}>
            {t("Sign up here")}
          </Button>
        </div>
      </div>
    </DialogContent>
  );
};

const DemoWrapper: React.FC<IDemoWrapper> = ({ children, demoComponent, permission }) => {
  const { isAuthenticated } = useAuthStore();
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = React.useState(false);
  const { state: isModalShow, setTrue: showModal, setFalse: closeModal } = useBooleanState(false);

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.bubbles = false;
    showModal();
    return false;
  };

  const canAccessFeature = useProfileHasAnyPermission(permission);
  const demoForm = isLogin ? <DemoLoginForm setIsLogin={setIsLogin} /> : <DemoRegistrationForm setIsLogin={setIsLogin} />;

  if (!canAccessFeature) {
    return (
      <React.Suspense fallback={<PagePreloader />}>
        <div style={{ position: "relative" }}>
          {demoComponent || (
            <div className="px-5 text-center">
              <h3 className="mb-5">
                {isAuthenticated
                  ? t("Oops! This feature is not available to your membership level.")
                  : t("Oops! This feature is only available for Airvana members.")}
              </h3>
              {isAuthenticated ? (
                <Link to="/membership/select/" className="text-decoration-none">
                  <Button>{t("Upgrade your membership")}</Button>
                </Link>
              ) : (
                <Link to="/sign-up/" className="text-decoration-none">
                  <Button>{t("Sign up for free")}</Button>
                </Link>
              )}
            </div>
          )}
          <a
            onClick={handleClick}
            tabIndex={-1}
            title=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
          />
        </div>
        <Dialog open={isModalShow} onOpenChange={closeModal}>
          {isAuthenticated ? (
            <DialogContent className="p-4 md:p-6 overflow-y-auto overflow-x-hidden flex max-h-screen max-w-full flex-col md:min-w-[400px] md:max-w-[450px] w-full min-h-screen md:min-h-fit">
              <DialogTitle>{t("Oops! This section is not available to your Advanced Tier.")}</DialogTitle>
              <DialogDescription className="sr-only" />
              <div className="flex-1">
                <Col xs={{ span: 22, offset: 1 }}>
                  <Text fontSize="7" alignment="start" style={{ marginBottom: 16 }}>
                    {t("Upgrade your subscription today to get nationwide access to all our features:")}
                  </Text>
                  <ul className="av-list av-list--small">
                    <li className="av-list__item">
                      {t("Evaluate thousands of properties at once with automated underwriting and evaluation")}
                    </li>
                    <li className="av-list__item">{t("Customize and edit financial analysis for any property")}</li>
                    <li className="av-list__item">{t("Explore deals across hundreds of markets, all in one place")}</li>
                    <li className="av-list__item">
                      {t("Sort and filter properties to view opportunities relevant your investment criteria")}
                    </li>
                    <li className="av-list__item">{t("Receive automatic alerts for deals that match your investment criteria")}</li>
                    <li className="av-list__item">{t("Explore hundreds of new properties every day")}</li>
                  </ul>
                </Col>
              </div>
              <DialogFooter className="flex !flex-col pb-0">
                <Link className="w-100 d-block" to="/membership/select/">
                  <Button className="w-100">{t("Upgrade tier")}</Button>
                </Link>
                <Divider className="w-100" text={t("or") as string} />
                <BookCall className="w-100 !text-foreground" buttonVariant="outline" buttonText={t("Speak with sales")} />
              </DialogFooter>
            </DialogContent>
          ) : (
            demoForm
          )}
        </Dialog>
      </React.Suspense>
    );
  }

  return (
    <React.Suspense fallback={<PagePreloader />}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default DemoWrapper;

import React from "react";
import type { RouteObject } from "react-router-dom";
import ErrorPage from "src/pages/ErrorPage";
import scrapersRouter from "src/pages/admin/scrapers/router";
import schedulersRouter from "src/pages/admin/schedulers/router";
import membershipsRouter from "src/pages/admin/memberships/router";
import propertiesRouter from "src/pages/admin/properties/router";
import localPulseRouter from "src/pages/admin/local-pulse/router";
import Layout from "src/pages/Layout";
import AdminWrapper from "src/pages/admin/AdminWrapper";
import AdminRequiredWrapper from "src/pages/admin/AdminRequiredWrapper";
import lazyWithRetry from "src/utils/lazy-with-retry";

const DashboardPage = lazyWithRetry(() => import("src/pages/admin/DashboardPage"));

const adminRoutes: RouteObject = {
  path: "/admin/",
  element: <Layout />,
  errorElement: <ErrorPage />,
  children: [
    {
      element: <AdminWrapper />,
      children: [
        {
          index: true,
          element: <AdminRequiredWrapper><DashboardPage /></AdminRequiredWrapper>,
        },
        ...scrapersRouter,
        ...schedulersRouter,
        ...membershipsRouter,
        ...propertiesRouter,
        ...localPulseRouter,
      ],
    },
  ],
};

export default adminRoutes;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "src/components/ui/button";
import { PropertyStatus } from "src/enums/property";
import Modal from "react-bootstrap/esm/Modal";
import { useBooleanState } from "src/hooks/boolean";
import { useGetPropertyRequest, useUpdatePropertyInfoRequest } from "src/hooks/api/admin/property";
import { useToastStore } from "src/store/toast";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Input } from "src/components/ui/input";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "src/components/ui/zForm";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { cn } from "src/lib/utils";
import { MultiSelect, SelectOption } from "src/components/ui/multiSelect";
import { Textarea } from "src/components/ui/textarea";

interface UpdatePropertyInfoProps {
  property: AdminAPI.Property.PropertyData;
  hide: () => void;
  onRefetch?: () => void;
}

interface UpdatePropertyProps {
  id: string | number;
  property?: AdminAPI.Property.PropertyData;
  onRefetch?: () => void;
  ButtonElement?: React.ReactNode;
  className?: string;
  variant?: "default" | "outline";
}

const formSchema = z.object({
  bedrooms: z.string(),
  bathrooms: z.string(),
  preference: z.string(),
  external_listed_at: z.coerce.date({
    required_error: "External listed date is required",
    invalid_type_error: "Invalid date format",
  }),
  internal_listed_at: z.coerce.date({
    required_error: "Internal listed date is required",
    invalid_type_error: "Invalid date format",
  }),
  external_url: z.union([
    z.string().url({
      message: "Invalid URL",
    }),
    z.literal(""), // Allow empty string
  ]),
  name: z.string(),
  phone: z.string(),
  business_name: z.string(),
  email: z.union([
    z.string().email(),
    z.literal(""), // Allow empty string
  ]),
  contact_status: z.string() as z.ZodType<AdminAPI.Property.PropertyContactStatus>,
  contact_summary: z.string(),
  furnishing: z.string(),
  keyword_category: z.array(z.string()),
  income_requirement: z.string().refine((val) => !Number.isNaN(Number(val)), {
    message: "Income requirement must be a valid number",
  }),
  apply_options: z.array(z.string()),
  lease_length: z.array(z.string()),
  amenities: z.array(z.string()),
  status: z.string(),
  aware_str_regulations: z.string(),
  additional_information: z.string(),
  consider_selling: z.string(),
  consider_selling_period: z.string(),
  interested_in_portfolio_expand: z.string(),
  interested_in_portfolio_expand_details: z.string(),
  want_be_reached: z.string(),
  have_management_team: z.string(),
  open_for_property_management: z.string(),
});

const formatDateToYYYYMMDD = (date?: Date | string | null): string => {
  if (!date) return ""; // Handle null, undefined, or empty input

  const d = date instanceof Date ? new Date(date.getTime()) : new Date(date);

  if (Number.isNaN(d.getTime())) return ""; // Ensure the date is valid

  // Extract UTC date components to prevent timezone shifts
  const year = d.getUTCFullYear();
  const month = String(d.getUTCMonth() + 1).padStart(2, "0");
  const day = String(d.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const leaseLengthMultiSelectCategories: SelectOption[] = [
  {
    label: "6 month lease",
    value: "month_lease_6",
  },
  {
    label: "1 year lease",
    value: "year_lease_1",
  },
  {
    label: "2 years lease",
    value: "year_lease_2",
  },
  {
    label: "4 years lease",
    value: "year_lease_4",
  },
];

export const amenitiesMultiSelectCategories: SelectOption[] = [
  {
    label: "Pool",
    value: "pool",
  },
  {
    label: "Back yard",
    value: "backyard",
  },
  {
    label: "Garage",
    value: "garage",
  },
  {
    label: "Hot tub",
    value: "hottub",
  },
];

export const applyOptionsMultiSelectCategories: SelectOption[] = [
  {
    label: "I accept applications via Zillow",
    value: "zillow",
  },
  {
    label: "I need help with collecting Renter Applications",
    value: "renter_applications",
  },
  {
    label: "I use a third party application software",
    value: "third_party",
  },
];

export const propertyCategoryMultiSelectCategories: SelectOption[] = [
  {
    label: "Arbitrage",
    value: "arbitrage",
  },
  {
    label: "Co-host",
    value: "co_host",
  },
  {
    label: "Apartment",
    value: "apartment",
  },
];

const UpdatePropertyInfo: React.FC<UpdatePropertyInfoProps> = ({ property, onRefetch, hide }) => {
  const { addToastSuccess, addToastDanger, addToastWarning } = useToastStore();
  const {
    data: updatePropertyInfoData,
    error: updatePropertyInfoError,
    mutate: updatePropertyInfo,
    isError: isUpdatePropertyInfoError,
    status: updatePropertyInfoStatus,
  } = useUpdatePropertyInfoRequest(property.id);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      bedrooms: property.property.bedrooms.toString(),
      bathrooms: property.property.bathrooms !== undefined ? Number(property.property.bathrooms).toFixed(1) : "1.0",
      external_listed_at: new Date(property.property.external_listed_at || ""),
      internal_listed_at: new Date(property.property.internal_listed_at || ""),
      external_url: property.property.external_url || "",
      name: property.contact?.name || "",
      phone: property.contact?.phone || "",
      business_name: property.contact?.business_name || "",
      email: property.contact?.email || "",
      contact_status: property.contact?.contact_status || "not_set",
      contact_summary: property.contact?.contact_summary || "",
      status: property.status || "",
      keyword_category: property.property.keyword_category || [],
      furnishing: property.landlord_form?.furnishing || "not_set",
      income_requirement: property.landlord_form?.income_requirement?.toString() || "0",
      preference: property.landlord_form?.preference || "both",
      apply_options: property.landlord_form?.apply_options || [],
      lease_length: property.landlord_form?.lease_length || [],
      amenities: property.landlord_form?.amenities || [],
      aware_str_regulations: property.landlord_form?.aware_str_regulations || "",
      additional_information: property.landlord_form?.additional_information || "",
      consider_selling: property.landlord_form?.consider_selling || "",
      consider_selling_period: property.landlord_form?.consider_selling_period || "",
      interested_in_portfolio_expand: property.landlord_form?.interested_in_portfolio_expand || "",
      interested_in_portfolio_expand_details: property.landlord_form?.interested_in_portfolio_expand_details || "",
      want_be_reached: property.landlord_form?.want_be_reached || "",
      have_management_team: property.landlord_form?.have_management_team || "",
      open_for_property_management: property.landlord_form?.open_for_property_management || "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    const updatedValues = { ...values }; // Create a copy of the values

    try {
      if (values.phone && !values.phone.startsWith("+1")) {
        updatedValues.phone = `+1${updatedValues.phone}`;
      }

      // if name,phone,business_name,email are empty, don't add contact to the property

      const updatedProperty = {
        property: {
          bedrooms: updatedValues.bedrooms,
          bathrooms: updatedValues.bathrooms,
          external_listed_at: formatDateToYYYYMMDD(updatedValues.external_listed_at),
          internal_listed_at: formatDateToYYYYMMDD(updatedValues.internal_listed_at),
          external_url: updatedValues.external_url,
          furnishing: updatedValues.furnishing || "not_set",
          keyword_category: updatedValues.keyword_category as API.Property.PropertyKeywordCategory[],
          status: updatedValues.status as PropertyStatus,
          contact: {
            name: updatedValues.name,
            phone: updatedValues.phone,
            business_name: updatedValues.business_name,
            email: updatedValues.email,
            contact_status: updatedValues.contact_status,
            contact_summary: updatedValues.contact_summary,
          } as any,
          landlord_form: {
            furnishing: updatedValues.furnishing || "not_set",
            income_requirement: updatedValues.income_requirement,
            apply_options: updatedValues.apply_options,
            lease_length: updatedValues.lease_length,
            amenities: updatedValues.amenities,
            aware_str_regulations: updatedValues.aware_str_regulations,
            additional_information: updatedValues.additional_information,
            consider_selling: updatedValues.consider_selling,
            consider_selling_period: updatedValues.consider_selling_period,
            interested_in_portfolio_expand: updatedValues.interested_in_portfolio_expand,
            interested_in_portfolio_expand_details: updatedValues.interested_in_portfolio_expand_details,
            want_be_reached: updatedValues.want_be_reached,
            have_management_team: updatedValues.have_management_team,
            open_for_property_management: updatedValues.open_for_property_management,
            preference: updatedValues.preference,
          },
        },
      };

      // Remove contact from updatedProperty if all contact fields are empty
      if (
        !updatedValues.name &&
        !updatedValues.phone &&
        !updatedValues.business_name &&
        !updatedValues.email &&
        !updatedValues.contact_summary
      ) {
        delete updatedProperty.property.contact;
      }

      updatePropertyInfo(updatedProperty);
    } catch (error) {
      console.error(error);
    }
  }

  React.useEffect(() => {
    if (updatePropertyInfoStatus === "success") {
      addToastSuccess({
        title: "Property info updated successfully",
      });
      hide();
      onRefetch?.();
    } else if (updatePropertyInfoStatus === "error") {
      addToastDanger({
        title: JSON.stringify((updatePropertyInfoError as any)?.response?.data) || "Failed to update property info",
      });
    } else if (updatePropertyInfoStatus === "loading") {
      addToastWarning({
        title: "Updating property info...",
      });
    }
  }, [updatePropertyInfoStatus]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col max-h-[85vh]">
        <Modal.Body className="flex-1 overflow-y-auto customScrollbar2">
          <div className="space-y-3">
            <div id="property-info">
              <FormLabel className="text-lg font-bold">Property info</FormLabel>
              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="bedrooms"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Bedrooms</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select bedrooms" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="1">1BR</SelectItem>
                            <SelectItem value="2">2BR</SelectItem>
                            <SelectItem value="3">3BR</SelectItem>
                            <SelectItem value="4">4BR</SelectItem>
                            <SelectItem value="5">5BR</SelectItem>
                            <SelectItem value="6">6BR</SelectItem>
                            <SelectItem value="7">7BR</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormDescription>Number of bedrooms in the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="bathrooms"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Bathrooms</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select bathrooms" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="1.0">1BA</SelectItem>
                            <SelectItem value="1.5">1.5BA</SelectItem>
                            <SelectItem value="2.0">2BA</SelectItem>
                            <SelectItem value="2.5">2.5BA</SelectItem>
                            <SelectItem value="3.0">3BA</SelectItem>
                            <SelectItem value="3.5">3.5BA</SelectItem>
                            <SelectItem value="4.0">4BA</SelectItem>
                            <SelectItem value="4.5">4.5BA</SelectItem>
                            <SelectItem value="5.0">5BA</SelectItem>
                            <SelectItem value="5.5">5.5BA</SelectItem>
                            <SelectItem value="6.0">6BA</SelectItem>
                            <SelectItem value="6.5">6.5BA</SelectItem>
                            <SelectItem value="7.0">7BA</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormDescription>Number of bathrooms in the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="external_listed_at"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>External listed Date</FormLabel>
                      <FormControl>
                        <Input {...field} type="date" value={formatDateToYYYYMMDD(field.value)} />
                      </FormControl>

                      <FormDescription>When the property was listed on the external site.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="internal_listed_at"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Internal Listed Date</FormLabel>
                      <FormControl>
                        <Input {...field} type="date" value={formatDateToYYYYMMDD(field.value)} />
                      </FormControl>
                      <FormDescription>When the property was listed in our airvana.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <FormLabel>Property Status</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select property status" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="verified">Verified</SelectItem>
                            <SelectItem value="listed">Listed</SelectItem>
                            <SelectItem value="leased">Leased</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormDescription>The status of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="furnishing"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <FormLabel>Furnishing</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select furnishing" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="not_set">Not set</SelectItem>
                            <SelectItem value="furnished">Furnished</SelectItem>
                            <SelectItem value="not_furnished">Not furnished</SelectItem>
                            <SelectItem value="partially_furnished">Partially furnished</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormDescription>The furnishing of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="keyword_category"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Property Category</FormLabel>
                      <FormControl>
                        <MultiSelect
                          multiple
                          options={propertyCategoryMultiSelectCategories}
                          value={
                            propertyCategoryMultiSelectCategories
                              ? propertyCategoryMultiSelectCategories.filter((cat) => field.value?.includes(String(cat.value)))
                              : []
                          }
                          onChange={(values: SelectOption[]) => {
                            field.onChange(values.map((v) => String(v.value)));
                          }}
                        />
                      </FormControl>
                      <FormDescription>The category of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="external_url"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>External url</FormLabel>
                      <FormControl>
                        <Input {...field} type="text" value={field.value} />
                      </FormControl>
                      <FormDescription>The url of the property on the external site.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div id="contact-info">
              <FormLabel className="text-lg font-bold mt-3">Contact info</FormLabel>
              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} type="text" />
                      </FormControl>
                      <FormDescription>The name of the Landlord.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="business_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Business name</FormLabel>
                      <FormControl>
                        <Input {...field} type="text" />
                      </FormControl>
                      <FormDescription>The business name of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email</FormLabel>
                      <FormControl>
                        <Input {...field} type="text" />
                      </FormControl>
                      <FormDescription>The email of the Landlord.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone</FormLabel>
                      <FormControl>
                        <Input {...field} type="text" />
                      </FormControl>
                      <FormDescription>The phone number of the Landlord.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="contact_status"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contact status</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select contact status" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="not_set">Not Set</SelectItem>
                            <SelectItem value="no_answer">No Answer</SelectItem>
                            <SelectItem value="comfortable">Comfortable (Lead)</SelectItem>
                            <SelectItem value="expressed_interest">Expressed Interest (Warm Lead)</SelectItem>
                            <SelectItem value="not_interested">Not Interested</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="contact_summary"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Contact summary</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div id="landlord-form">
              <FormLabel className="text-lg font-bold">Landlord form</FormLabel>
              <div className="grid grid-cols-2 gap-3">
                <FormField
                  control={form.control}
                  name="income_requirement"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Income requirement</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" />
                      </FormControl>
                      <FormDescription>The income requirement of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="preference"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Preference</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select preference" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="both">Both</SelectItem>
                            <SelectItem value="renter">Renter</SelectItem>
                            <SelectItem value="business">Business</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="apply_options"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Apply options</FormLabel>
                      <FormControl>
                        <MultiSelect
                          multiple
                          options={applyOptionsMultiSelectCategories}
                          value={
                            applyOptionsMultiSelectCategories
                              ? applyOptionsMultiSelectCategories.filter((cat) => field.value?.includes(String(cat.value)))
                              : []
                          }
                          onChange={(values: SelectOption[]) => {
                            field.onChange(values.map((v) => String(v.value)));
                          }}
                        />
                      </FormControl>
                      <FormDescription>The apply options of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lease_length"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Lease length</FormLabel>
                      <FormControl>
                        <MultiSelect
                          multiple
                          options={leaseLengthMultiSelectCategories}
                          value={
                            leaseLengthMultiSelectCategories
                              ? leaseLengthMultiSelectCategories.filter((cat) => field.value?.includes(String(cat.value)))
                              : []
                          }
                          onChange={(values: SelectOption[]) => {
                            field.onChange(values.map((v) => String(v.value)));
                          }}
                        />
                      </FormControl>
                      <FormDescription>The lease length of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="amenities"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Amenities</FormLabel>
                      <FormControl>
                        <MultiSelect
                          multiple
                          options={amenitiesMultiSelectCategories}
                          value={
                            amenitiesMultiSelectCategories
                              ? amenitiesMultiSelectCategories.filter((cat) => field.value?.includes(String(cat.value)))
                              : []
                          }
                          onChange={(values: SelectOption[]) => {
                            field.onChange(values.map((v) => String(v.value)));
                          }}
                        />
                      </FormControl>
                      <FormDescription>The amenities of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="aware_str_regulations"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Aware STR regulations</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select aware STR regulations" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="yes">Yes</SelectItem>
                            <SelectItem value="no">No</SelectItem>
                            <SelectItem value="not_sure">Not sure</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="consider_selling"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Consider selling</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select aware STR regulations" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="yes">Yes</SelectItem>
                            <SelectItem value="no">No</SelectItem>
                            <SelectItem value="maybe">Maybe (I would like to see an offer)</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="consider_selling_period"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Consider selling period</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select consider selling period" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="immediately">Immediately</SelectItem>
                            <SelectItem value="month">Month</SelectItem>
                            <SelectItem value="curious">Just curious</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="interested_in_portfolio_expand"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Interested in portfolio expand</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Interested in portfolio expand" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="yes">Yes</SelectItem>
                            <SelectItem value="no">No</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="want_be_reached"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Want be reached</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select want be reached" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="yes">Yes</SelectItem>
                            <SelectItem value="no">No</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="have_management_team"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Have management team</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select have management team" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="yes">Yes</SelectItem>
                            <SelectItem value="no">No</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="open_for_property_management"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Open for property management</FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={(value) => field.onChange(value)}>
                          <SelectTrigger>
                            <SelectValue placeholder="Select open for property management" />
                          </SelectTrigger>
                          <SelectContent className="z-[9999]">
                            <SelectItem value="yes">Yes</SelectItem>
                            <SelectItem value="no">No</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="interested_in_portfolio_expand_details"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Interested in portfolio expand details</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="additional_information"
                  render={({ field }) => (
                    <FormItem className="col-span-2">
                      <FormLabel>Additional information</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormDescription>The additional information of the property.</FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex flex-row gap-2 p-3 border-t flex-nowrap">
          <Button type="button" variant="outline" className="w-full" onClick={hide}>
            Cancel
          </Button>
          <Button type="submit" className="w-full" variant="default" disabled={!form.formState.isDirty}>
            Submit
          </Button>
        </Modal.Footer>
      </form>
    </Form>
  );
};

const UpdateProperty: React.FC<UpdatePropertyProps> = ({ id, property, onRefetch, ButtonElement, className, variant = "default" }) => {
  const { t } = useTranslation();
  const { state: isShow, setTrue: show, setFalse: hide } = useBooleanState(false);

  const { data, remove, error, refetch } = property
    ? { data: { data: property }, remove: () => {}, error: null, refetch: () => {} }
    : useGetPropertyRequest(id);

  const propertyData = useMemo(() => data?.data, [data]);

  const handleRefetch = () => {
    refetch();
    onRefetch?.();
  };

  return (
    <>
      <Button onClick={show} size="sm" variant={variant} className={cn("h-full", className)}>
        {ButtonElement || t("Update property info")}
      </Button>

      <Modal show={isShow} className="flex items-center justify-center">
        <Modal.Header closeButton onHide={hide} className="!p-3">
          <Modal.Title>{t("Update property info")}</Modal.Title>
        </Modal.Header>
        {propertyData && <UpdatePropertyInfo property={propertyData} onRefetch={handleRefetch} hide={hide} />}
      </Modal>
    </>
  );
};

export default UpdateProperty;
